import axios from "axios";
import { useEffect, useState } from "react";
import { Pagination } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  ACODE_SHOPING_ORDER_STATUS,
  ADMIN_BADGE,
  MEMBERSHIP,
} from "../../../common/constants";
import AdminHeadFilter from "../../../components/AdminHeadFilter";
import AdminHeadTitle from "../../../components/AdminHeadTitle";
import { getColor } from "../../../components/getColor";
import RequestModal from "../../../components/modal/RequestModal";
import { reqReadAdminBadge } from "../../../requests/badge";
import { reqAllShipOrder } from "../../../requests/ship";
export default function AShipOrder() {
  const nav = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<string | null>(null);
  const [showRepackModal, setShowRepackModal] = useState(false);
  const [data, setData] = useState<OrderData[]>([]);

  type OrderData = {
    id: string;
    userId: string;
    adrReceiver: string;
    createdAt: string;
    status: number;
    user: {
      name: string;
      membershipId: number;
    };
  };

  const fetchData = async () => {
    try {
      const response = await reqAllShipOrder(); // reqAllShipOrder 함수 사용하여 데이터 요청
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data: ", error);
      nav("/admin/login"); // 에러 발생 시 관리자 페이지로 이동
    }
  };

  useEffect(() => {
    fetchData();

    const badgePayload = {
      badgeName: ADMIN_BADGE.SHIP_REQUEST_FORM,
    };
    reqReadAdminBadge(badgePayload);
  }, []);

  const refreshData = () => {
    fetchData();
  };

  const handleStatusClick = (orderId: string) => {
    setSelectedOrder(orderId);
    setShowModal(true);
  };

  const handleRepackRequest = () => {
    setShowModal(false);
    setShowRepackModal(true);
    fetchData();
  };

  const handleKeyPress = (event: any) => {
    if (event.key === " ") {
      event.preventDefault();
    }
  };

  //! 필터 시작
  const [filteredData, setFilteredData] = useState<any[]>([]);

  const [searchWord, setSearchWord] = useState<string>("");
  const [searchOption, setSearchOption] = useState<string>("");

  const [selectedStatus, setSelectedStatus] = useState("");

  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    const thisFilteredData = data
      .filter((order: any) =>
        order.id.toUpperCase().includes(searchWord.toUpperCase())
      )
      .sort(
        (a: any, b: any) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      );
    // const thisFilteredData = orders.filter(
    //   (order) =>
    //     order.orderId.toLowerCase().includes(searchWord.toLowerCase()) &&
    //     (selectedStatus ? order.status.toString() === selectedStatus : true)
    // );
    setFilteredData(thisFilteredData);
    setCurrentPage(1);
    setTotalPages(Math.ceil(thisFilteredData.length / itemsPerPage));
  }, [data, searchWord, itemsPerPage, selectedStatus]);

  const handleItemsPerPageChange = (newItemsPerPage: number) => {
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const displayedOrders = filteredData.slice(
    startIndex,
    startIndex + itemsPerPage
  );
  //! 필터 끝

  const tableRows = displayedOrders.map((item, index) => (
    <tr key={index}>
      <td>{index + 1 + startIndex}</td>
      <td>{item.id}</td>
      <td>{new Date(item.createdAt).toLocaleDateString()}</td>
      <td>{item.userId}</td>
      <td>{item.user.name}</td>
      <td style={{ color: getColor(item.user.membershipId) }}>
        {MEMBERSHIP[item.user.membershipId]}
      </td>
      <td>
        <button
          className="btn btn-primary btn-sm"
          onClick={() => handleStatusClick(item.id)}
        >
          {item.status === 1 ? "요청" : "요청확인"}
        </button>
      </td>
    </tr>
  ));

  return (
    <div>
      <AdminHeadTitle
        subtitle1="배송대행"
        subtitle2="배송대행 요청서"
        title="배송대행 요청서"
      />

      {/* 옵션 필터 */}
      <AdminHeadFilter
        selectViewPage={(value: number) => handleItemsPerPageChange(value)}
        enterSearch={(value: string) => setSearchWord(value)}
        selectSearchOption={(value: string) => setSearchOption(value)}
      />

      <TableContainer>
        <table>
          <thead>
            <tr>
              <th>No</th>
              <th>주문번호</th>
              <th>주문날짜</th>
              <th>스페이스코드</th>
              <th>성명</th>
              <th>멤버십 등급</th>
              <th>상태</th>
            </tr>
          </thead>
          <tbody>{tableRows}</tbody>
        </table>
      </TableContainer>

      {/* 페이지 바 */}
      <CenteredPagination style={{ marginTop: "1rem" }}>
        {[...Array(totalPages)].map((_, index) => (
          <Pagination.Item
            key={index}
            active={index + 1 === currentPage}
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </Pagination.Item>
        ))}
      </CenteredPagination>

      {showModal && (
        <RequestModal
          show={showModal}
          onHide={() => setShowModal(false)}
          orderNumber={selectedOrder ?? ""}
          refreshData={refreshData}
        />
      )}
    </div>
  );
}

const CenteredPagination = styled(Pagination)`
  display: flex;
  justify-content: center;
`;

const TableContainer = styled.div`
  width: 100%;
  margin-top: 40px;
  margin-bottom: 40px;

  table {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
      padding: 8px;
      text-align: center;
      border: 1px solid #ddd;
    }

    th {
      background-color: #f2f2f2;
    }
  }
`;

const Toolbar = styled.div`
  .right {
    display: flex;
    gap: 10px;
    width: 300px;
    float: right;

    button {
      width: 100px;
    }
  }

  .left {
    display: flex;
    gap: 10px;

    > div {
      width: 150px;

      button {
        border-radius: 20px;
      }
    }
  }
`;
