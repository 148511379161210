import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { Collapse, Dropdown, Nav, Navbar } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import { Link, Outlet, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { reqAdminLogout } from "../../requests/admin-auth";
import Icon from "../Icon";

export default function AdminLayout() {
  const nav = useNavigate();
  const admin = useSelector((state: any) => state.admin);
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(true);
  const [isMenuOpen, setIsMenuOpen] = useState<{
    shopping: boolean;
    shipping: boolean;
    warehouse: boolean;
    customer: boolean;
    service: boolean;
    fee: boolean;
    analysis: boolean;
    admin: boolean;
  }>({
    shopping: false,
    shipping: false,
    warehouse: false,
    customer: false,
    service: false,
    fee: true,
    analysis: false,
    admin: false,
  });

  const [isLogin, setIsLogin] = useState<boolean>(false);
  const sidebarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (admin.id <= 0 || !admin.id) {
      setIsLogin(false);
    } else {
      setIsLogin(true);
    }
  }, [admin]);

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target as Node)
      ) {
        closeSidebar();
      }
    };

    if (isSidebarOpen && isMobile) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isSidebarOpen]);

  useEffect(() => {
    if (!admin.accessToken) {
      // 액세스 토큰이 없을 경우 로그인 페이지로 이동
      nav("/admin/login");
    } else {
      setIsLogin(true);
    }
  }, [admin.accessToken, nav]);

  const handleLogout = async () => {
    try {
      const accessToken = admin.accessToken;
      if (accessToken) {
        await reqAdminLogout(accessToken);
        nav("/admin/login");
      } else {
        alert("로그인 정보가 없습니다.");
        nav("/admin/login"); // 액세스 토큰이 없어도 로그인 페이지로 이동
      }
    } catch (err) {
      console.error("로그아웃 중 오류 발생:", err);
      nav("/admin/login"); // 에러 발생 시에도 로그인 페이지로 이동
    }
  };

  return (
    <>
      <Navbar className="p-2 bg-dark">
        <Icon
          icon="list"
          onClick={(e) => {
            setIsSidebarOpen(!isSidebarOpen);
          }}
          style={{
            marginRight: "20px",
            marginLeft: "10px",
            cursor: "pointer",
            color: "white",
            fontSize: "24px",
          }}
        />
        <Navbar.Brand
          style={{ cursor: "pointer", color: "white" }}
          onClick={(e) => {
            nav("/admin");
          }}
        >
          Ontact Korea 관리자
        </Navbar.Brand>
        <Navbar.Collapse
          className="justify-content-end"
          style={{
            position: "relative",
            visibility: admin.id > 0 ? "visible" : "hidden",
          }}
        >
          <Dropdown align={"end"}>
            <Dropdown.Toggle
              variant="dark"
              id="dropdown-basic"
              style={{
                backgroundColor: "transparent",
                color: "white",
                border: "1px solid transparent",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Icon
                icon="person"
                style={{ fontSize: "24px" }}
                onClick={(e) => {}}
              />
              <span style={{ marginLeft: "5px" }}>{admin.name}</span>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={(e) => {}}>설정</Dropdown.Item>
              <Dropdown.Item
                onClick={(e) => {
                  nav("/");
                }}
              >
                To Customer
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item onClick={handleLogout}>로그아웃</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Navbar.Collapse>
      </Navbar>

      <SidebarOverlay
        isSidebarOpen={isSidebarOpen}
        onClick={() => setIsSidebarOpen(false)}
      />
      <Sidebar
        className="bg-dark"
        initial={{ x: 0 }}
        animate={{ x: isSidebarOpen ? 0 : -300 }}
        transition={{ bounce: false }}
        isSidebarOpen={isSidebarOpen}
        ref={sidebarRef}
      >
        <div className="sidebar-inner">
          {isLogin && (
            <Nav
              className="d-md-block bg-dark sidebar"
              activeKey=""
              onSelect={(selectedKey) => alert(`selected ${selectedKey}`)}
            >
              <Nav.Item>
                <button
                  onClick={(e) => {
                    setIsMenuOpen({
                      ...isMenuOpen,
                      shopping: !isMenuOpen.shopping,
                    });
                  }}
                >
                  <div>
                    <Icon icon="bag" style={{ marginRight: "4px" }} /> 구매대행
                    서비스
                  </div>
                  <div>
                    {!isMenuOpen.shopping && <Icon icon="caret-down" />}
                    {isMenuOpen.shopping && <Icon icon="caret-up" />}
                  </div>
                </button>
                <Collapse in={isMenuOpen.shopping}>
                  <div>
                    <Link to="/admin/shop/order" className="sidebar-link">
                      구매 대행 요청서
                    </Link>
                    <Link to="/admin/shop/pay/wise" className="sidebar-link">
                      구매 대행 결제 대기중
                    </Link>
                    <Link to="/admin/shop/paid" className="sidebar-link">
                      구매 대행 결제 완료
                    </Link>
                    <Link to="/admin/shop/expired" className="sidebar-link">
                      구매 대행 결제 기한 만료
                    </Link>
                    <Link to="/admin/shop/details" className="sidebar-link">
                      구매 대행 거래 내역
                    </Link>
                  </div>
                </Collapse>
              </Nav.Item>
              <Nav.Item>
                <button
                  onClick={(e) => {
                    setIsMenuOpen({
                      ...isMenuOpen,
                      shipping: !isMenuOpen.shipping,
                    });
                  }}
                >
                  <div>
                    <Icon icon="truck" style={{ marginRight: "4px" }} />{" "}
                    배송대행 서비스
                  </div>
                  <div>
                    {!isMenuOpen.shipping && <Icon icon="caret-down" />}
                    {isMenuOpen.shipping && <Icon icon="caret-up" />}
                  </div>
                </button>
                <Collapse in={isMenuOpen.shipping}>
                  <div>
                    <Link className="sidebar-link" to="/admin/ship/order">
                      배송 대행 요청서
                    </Link>
                    <Link className="sidebar-link" to="/admin/ship/boxing">
                      상품포장중
                    </Link>
                    <Link className="sidebar-link" to="/admin/ship/pay">
                      배송 대행 결제 대기중
                    </Link>
                    <Link className="sidebar-link" to="/admin/ship/paid">
                      배송 대행 결제 완료
                    </Link>
                    <Link className="sidebar-link" to="/admin/ship/expired">
                      배송대행 결제기한 만료
                    </Link>
                    <Link className="sidebar-link" to="/admin/ship/details">
                      배송 대행 거래 내역
                    </Link>
                  </div>
                </Collapse>
              </Nav.Item>
              <Nav.Item>
                <button
                  onClick={(e) => {
                    setIsMenuOpen({
                      ...isMenuOpen,
                      warehouse: !isMenuOpen.warehouse,
                    });
                  }}
                >
                  <div>
                    <Icon icon="box-seam" style={{ marginRight: "4px" }} /> 창고
                    관리
                  </div>
                  <div>
                    {!isMenuOpen.warehouse && <Icon icon="caret-down" />}
                    {isMenuOpen.warehouse && <Icon icon="caret-up" />}
                  </div>
                </button>
                <Collapse in={isMenuOpen.warehouse}>
                  <div>
                    <Link
                      className="sidebar-link"
                      to="/admin/warehouse/inbound"
                    >
                      입고 등록
                    </Link>
                    <Link
                      className="sidebar-link"
                      to="/admin/warehouse/Buyinbound"
                    >
                      입고 등록(구매대행)
                    </Link>
                    <Link
                      className="sidebar-link"
                      to="/admin/warehouse/history"
                    >
                      입고 기록
                    </Link>
                    <Link
                      className="sidebar-link"
                      to="/admin/warehouse/checkcontents"
                    >
                      체크컨텐츠 요청 내역
                    </Link>
                    <Link className="sidebar-link" to="/admin/warehouse/split">
                      스플릿
                    </Link>
                    <Link
                      className="sidebar-link"
                      to="/admin/warehouse/findlost"
                    >
                      분실물 색출 요청 내역
                    </Link>
                    <Link
                      className="sidebar-link"
                      to="/admin/warehouse/expired"
                    >
                      무료 기한 만료 상품
                    </Link>
                    <Link className="sidebar-link" to="/admin/warehouse/all">
                      전체 상품 관리
                    </Link>
                    <Link
                      className="sidebar-link"
                      to="/admin/warehouse/position"
                    >
                      저장 위치 관리
                    </Link>
                    <Link
                      className="sidebar-link"
                      to="/admin/warehouse/released"
                    >
                      출고 관리
                    </Link>
                    <Link className="sidebar-link" to="/admin/warehouse/survey">
                      재고 조사
                    </Link>
                  </div>
                </Collapse>
              </Nav.Item>
              <Nav.Item>
                <button
                  onClick={(e) => {
                    setIsMenuOpen({
                      ...isMenuOpen,
                      customer: !isMenuOpen.customer,
                    });
                  }}
                >
                  <div>
                    <Icon icon="person" style={{ marginRight: "4px" }} />
                    고객 관리
                  </div>
                  <div>
                    {!isMenuOpen.customer && <Icon icon="caret-down" />}
                    {isMenuOpen.customer && <Icon icon="caret-up" />}
                  </div>
                </button>
                <Collapse in={isMenuOpen.customer}>
                  <div>
                    <Link className="sidebar-link" to="/admin/member/list">
                      고객 리스트
                    </Link>
                    <Link
                      className="sidebar-link"
                      to="/admin/member/membership"
                    >
                      멤버쉽 관리
                    </Link>
                    <Link className="sidebar-link" to="/admin/member/coupon">
                      쿠폰 관리
                    </Link>
                    <Link className="sidebar-link" to="/admin/member/point">
                      포인트 관리
                    </Link>
                  </div>
                </Collapse>
              </Nav.Item>
              <Nav.Item>
                <button
                  onClick={(e) => {
                    setIsMenuOpen({
                      ...isMenuOpen,
                      service: !isMenuOpen.service,
                    });
                  }}
                >
                  <div>
                    <Icon
                      icon="hand-thumbs-up"
                      style={{ marginRight: "4px" }}
                    />
                    서비스 관리
                  </div>
                  <div>
                    {!isMenuOpen.service && <Icon icon="caret-down" />}
                    {isMenuOpen.service && <Icon icon="caret-up" />}
                  </div>
                </button>
                <Collapse in={isMenuOpen.service}>
                  <div>
                    <Link to="/admin/service/review" className="sidebar-link">
                      리뷰 관리
                    </Link>
                    <Link className="sidebar-link" to="/admin/service/qna">
                      질문 응답
                    </Link>
                    <Link to="/admin/service/event" className="sidebar-link">
                      이벤트 팝업
                    </Link>
                    <Link to="/admin/service/notice" className="sidebar-link">
                      공지사항 등록
                    </Link>
                  </div>
                </Collapse>
              </Nav.Item>

              {(admin.role === 1 || admin.role === 3) && (
                <Nav.Item>
                  <button
                    onClick={(e) => {
                      setIsMenuOpen({
                        ...isMenuOpen,
                        analysis: !isMenuOpen.analysis,
                      });
                    }}
                  >
                    <div>
                      <Icon
                        icon="hand-thumbs-up"
                        style={{ marginRight: "4px" }}
                      />
                      통계 리스트
                    </div>
                    <div>
                      {!isMenuOpen.analysis && <Icon icon="caret-down" />}
                      {isMenuOpen.analysis && <Icon icon="caret-up" />}
                    </div>
                  </button>
                  <Collapse in={isMenuOpen.analysis}>
                    <div>
                      <Link
                        to="/admin/analysis/newuser"
                        className="sidebar-link"
                      >
                        가입 통계
                      </Link>
                      <Link to="/admin/analysis/daily" className="sidebar-link">
                        일별 매출
                      </Link>
                      <Link to="/admin/analysis/ship" className="sidebar-link">
                        배송대행
                      </Link>
                      <Link to="/admin/analysis/shop" className="sidebar-link">
                        구매대행
                      </Link>
                      <Link
                        to="/admin/analysis/member"
                        className="sidebar-link"
                      >
                        고객별
                      </Link>
                    </div>
                  </Collapse>
                </Nav.Item>
              )}
              <div
                style={{
                  borderBottom: "1px solid #aaa",
                  width: "100%",
                  height: "1px",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              ></div>
              {isLogin && admin.role === 1 && (
                <Nav.Item>
                  <button
                    onClick={(e) => {
                      setIsMenuOpen({
                        ...isMenuOpen,
                        admin: !isMenuOpen.admin,
                      });
                    }}
                  >
                    <div>
                      <Icon icon="flag" style={{ marginRight: "4px" }} /> 최고
                      관리자
                    </div>
                    <div>
                      {!isMenuOpen.admin && <Icon icon="caret-down" />}
                      {isMenuOpen.admin && <Icon icon="caret-up" />}
                    </div>
                  </button>
                  <Collapse in={isMenuOpen.admin}>
                    <div>
                      <Link className="sidebar-link" to="/admin/invoice">
                        배송 견적서
                      </Link>
                      <Link className="sidebar-link" to="/admin/care">
                        고객 관리
                      </Link>
                      {/* <Link className="sidebar-link" to="/admin/space">
                        스페이스코드 변경(주의)
                      </Link> */}
                      <Link className="sidebar-link" to="/admin/register">
                        관리자 계정 관리
                      </Link>
                      <Link className="sidebar-link" to="/admin/memberships">
                        멤버쉽 관리
                      </Link>
                      <button
                        onClick={(e) => {
                          setIsMenuOpen({
                            ...isMenuOpen,
                            fee: !isMenuOpen.fee,
                          });
                        }}
                      >
                        <div>
                          <Icon
                            icon="credit-card"
                            style={{ marginRight: "4px" }}
                          />
                          배송 운임 관리
                        </div>
                        <div>
                          {!isMenuOpen.fee && <Icon icon="caret-down" />}
                          {isMenuOpen.fee && <Icon icon="caret-up" />}
                        </div>
                      </button>
                      <Collapse in={isMenuOpen.fee}>
                        <div>
                          <Link
                            className="sidebar-link"
                            to="/admin/shipmentfee"
                          >
                            배송운임 설정
                          </Link>
                          <Link className="sidebar-link" to="/admin/warehouse">
                            저장 위치 관리
                          </Link>
                        </div>
                      </Collapse>
                    </div>
                  </Collapse>
                </Nav.Item>
              )}
            </Nav>
          )}

          {!isLogin && (
            <Nav
              className="col-md-12 d-none d-md-block bg-dark sidebar"
              activeKey=""
              onSelect={(selectedKey) => alert(`selected ${selectedKey}`)}
            >
              <Nav.Item>
                <button
                  onClick={(e) => {
                    nav("/admin/login");
                  }}
                >
                  <div>
                    <Icon icon="person-badge" style={{ marginRight: "4px" }} />
                    관리자 로그인
                  </div>
                </button>
              </Nav.Item>
              <Nav.Item>
                <button
                  onClick={(e) => {
                    nav("/");
                  }}
                >
                  <div>
                    <Icon icon="front" style={{ marginRight: "4px" }} />
                    랜딩 페이지로
                  </div>
                </button>
              </Nav.Item>
              <Nav.Item>
                <button
                  onClick={(e) => {
                    nav("/customer");
                  }}
                >
                  <div>
                    <Icon icon="display" style={{ marginRight: "4px" }} />
                    회원 스페이스로
                  </div>
                </button>
              </Nav.Item>
            </Nav>
          )}
        </div>
      </Sidebar>

      <Content
        initial={{ width: "calc(100vw - 300px)" }}
        animate={{
          width: isSidebarOpen ? "calc(100vw - 300px)" : "100vw",
          transition: { bounce: false },
        }}
      >
        <div className="inner">
          <Outlet />
        </div>
      </Content>
    </>
  );
}

const Sidebar = styled(motion.div)<{ isSidebarOpen: boolean }>`
  position: fixed;
  left: 0;
  height: 100vh;
  max-height: 100%;
  overflow-y: scroll;
  width: 300px;
  z-index: 10;
  background-color: #343a40;

  @media (max-width: 768px) {
    width: 250px;
    transform: ${({ isSidebarOpen }) =>
      isSidebarOpen ? "translateX(0)" : "translateX(-250px)"};
  }

  .sidebar-inner {
    width: 100%;
    padding: 10px;
    padding-bottom: 120px;

    @media (max-width: 768px) {
      padding-bottom: 80px;
    }
  }

  button {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border: 1px solid transparent;
    background-color: transparent;
    color: #aaa;
    outline: none;
    padding: 10px;
    background-color: #eeeeee12;

    &:hover {
      color: white;
    }

    @media (max-width: 768px) {
      padding: 8px;
      font-size: 14px; /* 글자 크기 조절 */
    }
  }

  .sidebar-link {
    display: block;
    color: #aaa;
    margin: 20px;
    text-decoration: none;

    &:hover {
      color: white;
    }

    @media (max-width: 768px) {
      margin: 10px;
      font-size: 14px; /* 글자 크기 조절 */
    }
  }
`;

const SidebarOverlay = styled.div<{ isSidebarOpen: boolean }>`
  display: ${({ isSidebarOpen }) => (isSidebarOpen ? "block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 5;

  @media (min-width: 769px) {
    display: none;
  }
`;

const Content = styled(motion.div)`
  position: fixed;
  right: 0px;
  width: 100vw;
  height: calc(100vh - 55px);
  max-width: 100%;
  max-height: 100%;

  @media (max-width: 768px) {
    height: calc(100vh - 45px);
  }

  .inner {
    padding: 20px;
    width: 100%;
    height: 100%;
    overflow-y: scroll;

    @media (max-width: 768px) {
      padding: 15px;
    }
  }
`;
