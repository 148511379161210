import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Pagination, Table } from "react-bootstrap";
import styled from "styled-components";
import { reqShopSurvery, reqShopSurverys } from "../../../requests/shop";

interface Barcode {
  id: number;
  trackingNumber: string;
  type: "matched" | "unmatched" | "scanned";
}

interface ShopSurvey {
  id: number;
  status: string;
  spaceCode: string | null;
  managerName: string;
  startTime: string;
  endTime: string;
  barcodes?: Barcode[];
}

const InventorySurvey: React.FC = () => {
  const [surveys, setSurveys] = useState<ShopSurvey[]>([]);
  const [selectedSurvey, setSelectedSurvey] = useState<ShopSurvey | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const [statusFilter, setStatusFilter] = useState<
    "전체재고조사" | "부분재고조사" | "스페이스코드 재고조사" | ""
  >("");
  const [managerFilter, setManagerFilter] = useState("");
  const [idFilter, setIdFilter] = useState("");

  useEffect(() => {
    const fetchSurveys = async () => {
      try {
        const response = await reqShopSurvery();
        setSurveys(response.data);
      } catch (error) {
        console.error("Error fetching surveys:", error);
      }
    };

    fetchSurveys();
  }, []);

  const formatDateTime = (startTime: string, endTime: string) => {
    if (!startTime || !endTime) return "N/A";
    const start = new Date(startTime);
    const end = new Date(endTime);
    if (isNaN(start.getTime()) || isNaN(end.getTime())) return "N/A";
    const formattedStart = format(start, "yyyy-MM-dd / HH:mm");
    const formattedEnd = format(end, "HH:mm");
    return `${formattedStart} ~ ${formattedEnd}`;
  };

  const filteredSurveys = surveys.filter((survey) => {
    const matchesStatus = statusFilter === "" || survey.status === statusFilter;
    const matchesManager =
      managerFilter === "" || survey.managerName.includes(managerFilter);
    const matchesId =
      idFilter === "" || survey.id.toString().includes(idFilter);
    return matchesStatus && matchesManager && matchesId;
  });

  const totalPages = Math.ceil(filteredSurveys.length / itemsPerPage);
  const currentRecords = filteredSurveys.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleRowClick = async (survey: ShopSurvey) => {
    try {
      const response = await reqShopSurverys(survey.id);
      setSelectedSurvey(response.data);
      const modalElement = document.getElementById("surveyModal");
      if (modalElement) {
        const bootstrapModal = new (window as any).bootstrap.Modal(
          modalElement
        );
        bootstrapModal.show();
      }
    } catch (error) {
      console.error("Error fetching survey detail:", error);
    }
  };

  const renderSurveyDetailModal = () => {
    if (!selectedSurvey) return null;

    const barcodes = selectedSurvey.barcodes || [];

    const matchedBarcodes = barcodes.filter((b) => b.type === "matched");
    const unmatchedBarcodes = barcodes.filter((b) => b.type === "unmatched");
    const scannedBarcodes = barcodes.filter((b) => b.type === "scanned");

    const totalQuantity = matchedBarcodes.length + unmatchedBarcodes.length;
    const matchedQuantity = matchedBarcodes.length;
    const scannedQuantity = scannedBarcodes.length;

    const sortedScannedBarcodes = [...scannedBarcodes].sort((a, b) => {
      const aMatched = matchedBarcodes.some(
        (matched) => matched.trackingNumber === a.trackingNumber
      );
      const bMatched = matchedBarcodes.some(
        (matched) => matched.trackingNumber === b.trackingNumber
      );
      return Number(aMatched) - Number(bMatched);
    });

    return (
      <Modal
        show={!!selectedSurvey}
        onHide={() => setSelectedSurvey(null)}
        size="xl"
        id="surveyModal"
      >
        <Modal.Header closeButton>
          <Modal.Title>조사 상세 정보</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Table bordered>
              <tbody>
                <tr>
                  <th>조사번호</th>
                  <td>{selectedSurvey.id}</td>
                  <th>담당자</th>
                  <td>{selectedSurvey.managerName}</td>
                </tr>
                <tr>
                  <th>조사기간</th>
                  <td>
                    {formatDateTime(
                      selectedSurvey.startTime,
                      selectedSurvey.endTime
                    )}
                  </td>
                  <th>조사범위</th>
                  <td>{selectedSurvey.status}</td>
                </tr>
                <tr>
                  <th>총수량</th>
                  <td>{totalQuantity}</td>
                  <th>일치수량</th>
                  <td>{matchedQuantity}</td>
                </tr>
                <tr>
                  <th>조사수량</th>
                  <td>{scannedQuantity}</td>
                  <th>스페이스코드</th>
                  <td>{selectedSurvey.spaceCode || "N/A"}</td>
                </tr>
              </tbody>
            </Table>

            <div className="row">
              <div className="col-md-6">
                <h5>보관번호</h5>
                <Table bordered>
                  <thead>
                    <tr>
                      <th>번호</th>
                      <th>상태</th>
                    </tr>
                  </thead>
                  <tbody>
                    {unmatchedBarcodes.map((barcode) => (
                      <tr key={barcode.id}>
                        <td>{barcode.trackingNumber}</td>
                        <td>
                          <input type="checkbox" checked={false} disabled />{" "}
                          미일치
                        </td>
                      </tr>
                    ))}
                    {matchedBarcodes.map((barcode) => (
                      <tr key={barcode.id}>
                        <td>{barcode.trackingNumber}</td>
                        <td>
                          <input type="checkbox" checked={true} disabled /> 일치
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
              <div className="col-md-6">
                <h5>조사번호</h5>
                <Table bordered>
                  <thead>
                    <tr>
                      <th>번호</th>
                      <th>상태</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sortedScannedBarcodes.map((barcode) => {
                      const isMatched = matchedBarcodes.some(
                        (matched) =>
                          matched.trackingNumber === barcode.trackingNumber
                      );
                      return (
                        <tr key={barcode.id}>
                          <td>{barcode.trackingNumber}</td>
                          <td>
                            <input
                              type="checkbox"
                              checked={isMatched}
                              disabled
                            />{" "}
                            {isMatched ? "일치" : "미일치"}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setSelectedSurvey(null)}>
            닫기
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <>
      <main id="main" className="main">
        <h2>재고조사</h2>

        <div className="container-fluid mb-4">
          <div className="row">
            <div className="col-md-4">
              <Form.Label htmlFor="statusFilter">조사유형</Form.Label>
              <Form.Control
                as="select"
                id="statusFilter"
                value={statusFilter}
                onChange={(e) =>
                  setStatusFilter(
                    e.target.value as
                      | "전체재고조사"
                      | "부분재고조사"
                      | "스페이스코드 재고조사"
                      | ""
                  )
                }
              >
                <option value="">모두</option>
                <option value="전체재고조사">전체재고조사</option>
                <option value="부분재고조사">부분재고조사</option>
                <option value="스페이스코드 재고조사">
                  스페이스코드 재고조사
                </option>
              </Form.Control>
            </div>

            <div className="col-md-4">
              <Form.Label htmlFor="managerFilter">담당자</Form.Label>
              <Form.Control
                type="text"
                id="managerFilter"
                value={managerFilter}
                onChange={(e) => setManagerFilter(e.target.value)}
              />
            </div>

            <div className="col-md-4">
              <Form.Label htmlFor="idFilter">조사번호</Form.Label>
              <Form.Control
                type="text"
                id="idFilter"
                value={idFilter}
                onChange={(e) => setIdFilter(e.target.value)}
              />
            </div>
          </div>
        </div>

        <Table bordered hover responsive className="text-center">
          <thead>
            <tr>
              <th>조사번호</th>
              <th>날짜</th>
              <th>담당자</th>
              <th>조사유형</th>
            </tr>
          </thead>
          <tbody>
            {currentRecords.map((survey) => (
              <tr key={survey.id} onClick={() => handleRowClick(survey)}>
                <td>{survey.id}</td>
                <td>{formatDateTime(survey.startTime, survey.endTime)}</td>
                <td>{survey.managerName}</td>
                <td>{survey.status}</td>
              </tr>
            ))}
          </tbody>
        </Table>

        <CenteredPagination>
          {[...Array(totalPages)].map((_, index) => (
            <Pagination.Item
              key={index}
              active={index + 1 === currentPage}
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </Pagination.Item>
          ))}
        </CenteredPagination>

        {renderSurveyDetailModal()}
      </main>
    </>
  );
};

export default InventorySurvey;

const CenteredPagination = styled(Pagination)`
  display: flex;
  justify-content: center;
`;
