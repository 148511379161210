import Notice from "../Notice";
import styled from "styled-components";
import Icon from "../Icon";

const NoticePreviewModal = ({ notice, tmpImage, setViewNotice }: any) => {
  return (
    <>
      <Backdrop onClick={() => setViewNotice(false)} />
      <ModalWrapper>
        <ModalHeader>
          <Icon
            icon="x-lg"
            fontSize="2rem"
            onClick={() => setViewNotice(false)}
          ></Icon>
        </ModalHeader>
        <Notice
          notice={notice}
          tmpImage={tmpImage}
          setViewNotice={() => setViewNotice(false)}
        />
      </ModalWrapper>
    </>
  );
};

const Backdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

const ModalWrapper = styled.div`
  z-index: 1000;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  border-radius: 1rem;
  background-color: white;
  max-height: 90vh;
  overflow-y: auto;
`;
const ModalHeader = styled.div`
  width: 100%;
  background-color: lightgray;
  text-align: right;
  padding: 0.5rem 1rem;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
`;

export default NoticePreviewModal;
