import axios from "axios";
import { useEffect, useState } from "react";
import { Pagination, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  CODE_SHIPPING_COMPANY,
  CODE_SHIPPING_ORDER_STATUS,
  MEMBERSHIP,
} from "../../../common/constants";
import { formatDate } from "../../../common/format";
import AdminHeadFilter from "../../../components/AdminHeadFilter";
import AdminHeadTitle from "../../../components/AdminHeadTitle";
import { getColor } from "../../../components/getColor";
import RepackModalCP from "../../../components/modal/RepackModalCP";
import { reqAllShipaid } from "../../../requests/ship";

const ITEMS_PER_PAGE = 10;

export default function AShipPaid() {
  const nav = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<string | null>(null);
  const [showRepackModal, setShowRepackModal] = useState(false);
  const [data, setData] = useState<OrderData[]>([]);

  type OrderData = {
    id: string;
    createdAt: string;
    userId: string;
    adrReceiver: string;
    status: number;
    membershipGrade: string;
    manager: string;
    company: number;
    user: {
      name: string;
      membershipId: number;
    };
  };

  const getStatusText = (status: number) => {
    switch (status) {
      case 0:
        return "기본";
      case 1:
        return "요청서";
      case 2:
        return "포장 중";
      case 3:
        return "견적서";
      case 4:
        return "결제 대기중";
      case 5:
        return "입금 확인중";
      case 6:
        return "입금 완료";
      case 11:
        return "폐기";
      case 99:
        return "특송사 배송 중";
      default:
        return "알 수 없음";
    }
  };

  const refreshData = () => {
    fetchData();
  };

  // 로컬 스토리지에서 엑세스 토큰을 가져오는 함수
  const getAccessTokenFromLocalStorage = () => {
    const state = localStorage.getItem("state");
    if (state) {
      const parsedState = JSON.parse(state);
      return parsedState?.admin?.accessToken || null;
    }
    return null;
  };

  const fetchData = async () => {
    try {
      const response = await reqAllShipaid(); // reqAllShipaid 함수를 통해 요청
      const filteredData = response.data.filter(
        (item: OrderData) =>
          item.status === CODE_SHIPPING_ORDER_STATUS.PRODUCT_CHECK ||
          item.status === CODE_SHIPPING_ORDER_STATUS.PRODUCT_SHIPPING
      );
      setData(filteredData); // 필터링된 데이터를 상태에 저장
    } catch (error) {
      console.error("Error fetching data: ", error);
      nav("/admin/login"); // 오류 발생 시 로그인 페이지로 리다이렉트
    }
  };

  useEffect(() => {
    fetchData(); // 컴포넌트가 마운트될 때 데이터 가져오기
  }, []);

  // const fetchData = async () => {
  //   const accessToken = getAccessTokenFromLocalStorage(); // 엑세스 토큰 가져오기

  //   if (!accessToken) {
  //     console.error("No access token found");
  //     nav("/admin/login"); // 토큰이 없으면 로그인 페이지로 리다이렉트
  //     return;
  //   }

  //   try {
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_BASE_URL}ship/ontact`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${accessToken}`, // 엑세스 토큰을 헤더에 추가
  //         },
  //       }
  //     );
  //     const filteredData = response.data.filter(
  //       (item: OrderData) =>
  //         item.status === CODE_SHIPPING_ORDER_STATUS.PRODUCT_CHECK ||
  //         item.status === CODE_SHIPPING_ORDER_STATUS.PRODUCT_SHIPPING
  //     );
  //     setData(filteredData);
  //   } catch (error) {
  //     console.error("Error fetching data: ", error);
  //   }
  // };

  // useEffect(() => {
  //   fetchData(); // 컴포넌트 마운트 시 데이터 가져오기
  // }, []);

  const handleStatusClick = (orderId: string) => {
    setSelectedOrder(orderId);
    setShowModal(true);
  };

  const handleRepackRequest = () => {
    setShowModal(false);
    setShowRepackModal(true);
  };

  //! 필터 시작
  const [filteredData, setFilteredData] = useState<any[]>([]);

  const [searchWord, setSearchWord] = useState<string>("");
  const [searchOption, setSearchOption] = useState<string>("");

  const [selectedStatus, setSelectedStatus] = useState("");

  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    const thisFilteredData = data
      .filter((order: any) =>
        order.id.toUpperCase().includes(searchWord.toUpperCase())
      )
      .sort(
        (a: any, b: any) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      );
    // const thisFilteredData = orders.filter(
    //   (order) =>
    //     order.orderId.toLowerCase().includes(searchWord.toLowerCase()) &&
    //     (selectedStatus ? order.status.toString() === selectedStatus : true)
    // );
    setFilteredData(thisFilteredData);
    setCurrentPage(1);
    setTotalPages(Math.ceil(thisFilteredData.length / itemsPerPage));
  }, [data, searchWord, itemsPerPage, selectedStatus]);

  const handleItemsPerPageChange = (newItemsPerPage: number) => {
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const displayedOrders = filteredData.slice(
    startIndex,
    startIndex + itemsPerPage
  );
  //! 필터 끝

  const tableRows = displayedOrders.map((item, index) => (
    <tr key={index}>
      <td>{index + 1 + startIndex}</td>
      <td>{item.id}</td>
      <td>{formatDate(item.createdAt)}</td>
      <td>{item.userId}</td>
      <td>{item.adrReceiver}</td>
      <td style={{ color: getColor(item.user.membershipId) }}>
        {MEMBERSHIP[item.user.membershipId]}
      </td>
      <td>{item.manager}</td>
      <td>{CODE_SHIPPING_COMPANY[item.company] || "정보없음"}</td>
      <td>{getStatusText(item.status)}</td>
      <td>
        <button
          className="btn btn-primary btn-sm"
          onClick={() => handleStatusClick(item.id)}
        >
          {item.status === 1 ? "배송접수" : "배송접수"}
        </button>
      </td>
    </tr>
  ));

  // 띄어쓰기
  const handleKeyPress = (event: any) => {
    if (event.key === " ") {
      event.preventDefault();
    }
  };

  return (
    <div>
      <AdminHeadTitle
        subtitle1="배송대행"
        subtitle2="배송대행 결제 완료"
        title="배송대행 결제 완료"
      />

      {/* 옵션 필터 */}
      <AdminHeadFilter
        selectViewPage={(value: number) => handleItemsPerPageChange(value)}
        enterSearch={(value: string) => setSearchWord(value)}
        selectSearchOption={(value: string) => setSearchOption(value)}
      />

      <div className="table-responsive">
        <Table className="table-custom text-center">
          <thead className="table-light">
            <tr>
              <th>No</th>
              <th>주문번호</th>
              <th>주문날자</th>
              <th>스페이스코드</th>
              <th>성명</th>
              <th>멤버쉽 등급</th>
              <th>담당자</th>
              <th>배송사</th>
              <th>상태</th>
              <th>배송접수</th>
            </tr>
          </thead>
          <tbody>{tableRows}</tbody>
        </Table>
      </div>

      {/* 페이지 바 */}
      <CenteredPagination style={{ marginTop: "1rem" }}>
        {[...Array(totalPages)].map((_, index) => (
          <Pagination.Item
            key={index}
            active={index + 1 === currentPage}
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </Pagination.Item>
        ))}
      </CenteredPagination>

      {showModal && (
        <RepackModalCP
          show={showModal}
          onHide={() => setShowModal(false)}
          orderNumber={selectedOrder ?? ""}
          onRepackRequest={handleRepackRequest}
          refreshData={refreshData}
        />
      )}
    </div>
  );
}

const CenteredPagination = styled(Pagination)`
  display: flex;
  justify-content: center;
`;

const TableContainer = styled.div`
  width: 100%;
  margin-top: 40px;
  margin-bottom: 40px;

  table {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
      padding: 8px;
      text-align: center;
      border: 1px solid #ddd;
    }

    th {
      background-color: #f2f2f2;
    }
  }
`;

const Toolbar = styled.div`
  .right {
    display: flex;
    gap: 10px;
    width: 300px;
    float: right;

    button {
      width: 100px;
    }
  }

  .left {
    display: flex;
    gap: 10px;

    > div {
      width: 150px;

      button {
        border-radius: 20px;
      }
    }
  }
`;
