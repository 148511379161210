import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  CODE_SHIPPING_ORDER_STATUS,
  MEMBERSHIP,
} from "../../../common/constants";
import Title from "../../../components/Title";
import { getColor } from "../../../components/getColor";
import AShipDetailModal from "../../../components/modal/AShipDetailModal";
import AdminHeadTitle from "../../../components/AdminHeadTitle";
import AdminHeadFilter from "../../../components/AdminHeadFilter";
import { Pagination, Table } from "react-bootstrap";

const ITEMS_PER_PAGE = 10;
const MAX_PAGE_BUTTONS = 10;

export default function AShipDetails() {
  const nav = useNavigate();
  const [data, setData] = useState<OrderData[]>([]);
  const [selectedOrder, setSelectedOrder] = useState<string | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [showAShipExpiredModal, setShowAShipExpiredModal] = useState(false);
  const [showExpiredModal, setShowExpiredModal] = useState(false);

  type OrderData = {
    id: string;
    userId: string;
    adrReceiver: string;
    createdAt: string;
    paidAt: string;
    status: number;
    membershipGrade: string;
    manager: string;
    review: boolean;
    user: {
      name: string;
      membershipId: number;
    };
  };

  // 액세스 토큰을 로컬 스토리지에서 가져오는 함수
  const getAccessTokenFromLocalStorage = () => {
    const state = localStorage.getItem("state");
    if (state) {
      const parsedState = JSON.parse(state);
      return parsedState?.admin?.accessToken || null; // 액세스 토큰 반환
    }
    return null;
  };

  // 데이터 가져오기 (Authorization 헤더에 액세스 토큰 포함)
  const fetchData = async () => {
    const accessToken = getAccessTokenFromLocalStorage(); // 토큰 가져오기

    if (!accessToken) {
      console.error("No access token found");
      nav("/admin/login"); // 토큰이 없을 경우 로그인 페이지로 리디렉션
      return;
    }

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}ship/ontact`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`, // 액세스 토큰을 헤더에 추가
          },
        }
      );
      const filteredData = response.data.filter(
        (item: OrderData) => item.status !== 10
      );
      setData(filteredData); // 필터링한 데이터를 상태에 저장
    } catch (error) {
      console.error("Error fetching data: ", error);
      nav("/admin/login"); // 오류 발생 시 로그인 페이지로 이동
    }
  };

  useEffect(() => {
    fetchData(); // 컴포넌트가 마운트될 때 데이터 가져오기
  }, []);

  const getStatusText = (status: number) => {
    switch (status) {
      case 1:
        return "요청서";
      case 2:
        return "포장 중";
      case 3:
        return "결제 견적서";
      case 4:
        return "결제 대기중";
      case 5:
        return "결제 완료";
      case 6:
        return "특송사 배송 대기중";
      case 7:
        return "특송사 배송";
      case 8:
        return "특송사 배송 완료";
      case 9:
        return "리뷰 완료";
      case 11:
        return "폐기";
      default:
        return "알 수 없음";
    }
  };

  const handleStatusClick = (orderId: string) => {
    setSelectedOrder(orderId);
    setShowAShipExpiredModal(true);
  };

  const handleDeleteClick = (orderId: string) => {
    setSelectedOrder(orderId);
    setShowExpiredModal(true);
  };

  // const fetchData = async () => {
  //   try {
  //     const response = await axios.get(`${process.env.REACT_APP_BASE_URL}ship/ontact`);
  //     const filteredData = response.data.filter(
  //       (item: OrderData) => item.status === CODE_SHIPPING_ORDER_STATUS.WAIT_PAY
  //     );
  //     setData(filteredData);
  //   } catch (error) {
  //     console.error("Error fetching data: ", error);
  //   }
  // };

  const refreshData = () => {
    fetchData();
  };

  const handleRepackRequest = () => {
    setShowModal(false);
    setShowAShipExpiredModal(true);
    fetchData();
  };

  const handleConfirmDelete = () => {
    setShowModal(false);
  };

  //! 필터 시작
  const [filteredData, setFilteredData] = useState<any[]>([]);

  const [searchWord, setSearchWord] = useState<string>("");
  const [searchOption, setSearchOption] = useState<string>("");

  const [selectedStatus, setSelectedStatus] = useState("");

  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [currentButtonRange, setCurrentButtonRange] = useState([
    1,
    MAX_PAGE_BUTTONS,
  ]);

  useEffect(() => {
    const thisFilteredData = data
      .filter((order: any) => {
        if (searchOption === "스페이스 코드") {
          return (
            order.userId.toUpperCase().includes(searchWord.toUpperCase()) &&
            (selectedStatus ? order.status.toString() === selectedStatus : true)
          );
        }
        return (
          order.id.toUpperCase().includes(searchWord.toUpperCase()) &&
          (selectedStatus ? order.status.toString() === selectedStatus : true)
        );
      })
      .sort(
        (a: any, b: any) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );
    setFilteredData(thisFilteredData);
    setCurrentPage(1);
    setTotalPages(Math.ceil(thisFilteredData.length / itemsPerPage));
    setCurrentButtonRange([1, Math.min(MAX_PAGE_BUTTONS, totalPages)]);
  }, [data, searchWord, itemsPerPage, selectedStatus, totalPages]);

  const handleItemsPerPageChange = (newItemsPerPage: number) => {
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
    setCurrentButtonRange([1, Math.min(MAX_PAGE_BUTTONS, totalPages)]);
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    const newStart = Math.max(1, pageNumber - Math.floor(MAX_PAGE_BUTTONS / 2));
    const newEnd = Math.min(totalPages, newStart + MAX_PAGE_BUTTONS - 1);
    setCurrentButtonRange([newStart, newEnd]);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const displayedOrders = filteredData.slice(
    startIndex,
    startIndex + itemsPerPage
  );
  //! 필터 끝

  const tableRows = displayedOrders.map((item, index) => (
    <tr key={index}>
      <td>{index + 1 + startIndex}</td>
      <td
        onClick={() => handleStatusClick(item.id)}
        style={{
          cursor: "pointer",
          backgroundColor: "#f0f0f0",
          padding: "5px",
          borderRadius: "5px",
          transition: "background-color 0.3s",
        }}
      >
        {item.id}
      </td>
      <td>{item.userId}</td>
      <td>{item.user ? item.user.name : "Unknown User"}</td>
      <td style={{ color: getColor(item.user?.membershipId) }}>
        {item.user ? MEMBERSHIP[item.user.membershipId] : "No Membership"}
      </td>
      <td>{item.manager}</td>
      <td>
        {item.paidAt ? new Date(item.paidAt).toLocaleDateString() : "날자 없음"}
      </td>
      <td>{getStatusText(item.status)}</td>
      <td>{item.review ? "리뷰 작성" : "미작성"}</td>
    </tr>
  ));

  return (
    <div>
      <AdminHeadTitle
        subtitle1="배송대행"
        subtitle2="배송대행 거래내역"
        title="배송대행 거래내역"
      />

      {/* 옵션 필터 */}
      <AdminHeadFilter
        selectStatus={(value: string) => setSelectedStatus(value)}
        selectViewPage={(value: number) => handleItemsPerPageChange(value)}
        enterSearch={(value: string) => setSearchWord(value)}
        selectSearchOption={(value: string) => setSearchOption(value)}
      />

      <div className="table-responsive">
        <Table className="table-custom text-center">
          <thead className="table-light">
            <tr>
              <th>No</th>
              <th>주문번호</th>
              <th>스페이스코드</th>
              <th>성명</th>
              <th>멤버쉽 등급</th>
              <th>담당자</th>
              <th>결제날자</th>
              <th>상태</th>
              <th>리뷰</th>
            </tr>
          </thead>
          <tbody>{tableRows}</tbody>
        </Table>
      </div>

      {/* 페이지 바 */}
      <CenteredPagination style={{ marginTop: "1rem" }}>
        <Pagination.Prev
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        />
        {[...Array(totalPages)].map((_, index) => {
          const pageNumber = index + 1;
          if (
            pageNumber >= currentButtonRange[0] &&
            pageNumber <= currentButtonRange[1]
          ) {
            return (
              <Pagination.Item
                key={pageNumber}
                active={pageNumber === currentPage}
                onClick={() => handlePageChange(pageNumber)}
              >
                {pageNumber}
              </Pagination.Item>
            );
          }
          return null;
        })}
        <Pagination.Next
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        />
      </CenteredPagination>

      {showAShipExpiredModal && (
        <AShipDetailModal
          show={showAShipExpiredModal}
          onHide={() => setShowAShipExpiredModal(false)}
          orderNumber={selectedOrder ?? ""}
          onRepackRequest={handleRepackRequest}
          refreshData={refreshData}
        />
      )}
    </div>
  );
}

const CenteredPagination = styled(Pagination)`
  display: flex;
  justify-content: center;
`;

const TableContainer = styled.div`
  width: 100%;
  margin-top: 40px;
  margin-bottom: 40px;

  table {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
      padding: 8px;
      text-align: center;
      border: 1px solid #ddd;
    }

    th {
      background-color: #f2f2f2;
    }
  }
`;

const Toolbar = styled.div`
  .right {
    display: flex;
    gap: 10px;
    width: 300px;
    float: right;

    button {
      width: 100px;
    }
  }

  .left {
    display: flex;
    gap: 10px;

    > div {
      width: 150px;

      button {
        border-radius: 20px;
      }
    }
  }
`;
