import { Button, Table } from "react-bootstrap";
import Title from "../../components/Title";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { modalOpen } from "../../redux/modalSlice";
import { MODAL_TYPE } from "../../components/modal/GlobalModal";
import { useSelector } from "react-redux";
import { reqGetAdminAuth, reqAdminUpdate } from "../../requests/admin-auth"; // 잠금 해지 요청 함수 가져오기
import FormatData from "../../components/FormatData";
import { DATA_TYPE } from "../../common/constants";
import AdminHeadTitle from "../../components/AdminHeadTitle";
import Swal from "sweetalert2"; // SweetAlert2 import

export default function ARegister() {
  const dispatch = useDispatch();
  const modal = useSelector((state: any) => state.modal);
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    const rf = async () => {
      const result = await reqGetAdminAuth();
      setData(result.data);
    };
    rf();
  }, [modal.refresh]);

  // 잠금 해지 함수
  const handleUnlock = async (id: string) => {
    const result = await Swal.fire({
      title: "잠금 해제를 하시겠습니까?",
      text: "잠금 해제 후 복구할 수 없습니다.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "예, 잠금 해제",
      cancelButtonText: "취소",
    });

    if (result.isConfirmed) {
      try {
        await reqAdminUpdate({ id, status: 0 });
        Swal.fire("해제됨", "계정 잠금이 해제되었습니다.", "success");
        // 잠금 해제 후 데이터를 다시 로드하여 최신 상태로 유지
        const updatedResult = await reqGetAdminAuth();
        setData(updatedResult.data);
      } catch (error) {
        Swal.fire("오류", "잠금 해제에 실패했습니다.", "error");
      }
    }
  };

  // 모달을 띄우는 함수
  const handleRowClick = (v: any, e: any) => {
    if (e.target.tagName !== "BUTTON") { // 버튼을 클릭한 것이 아니라면 모달을 띄움
      dispatch(
        modalOpen({
          modalType: MODAL_TYPE.ADMIN_REGISTER_DETAIL,
          title: "계정 정보",
          payload: { data: v },
        })
      );
    }
  };

  return (
    <>
      <AdminHeadTitle
        subtitle1="최고 관리자"
        subtitle2="관리자 계정 관리"
        title="관리자 계정 관리"
      />
      <div
        style={{ height: "500px", overflowY: "scroll", marginBottom: "30px" }}
      >
        <Table>
          <thead>
            <tr>
              <th style={{ width: "70px" }}>번호</th>
              <th>이름</th>
              <th>아이디</th>
              <th>등록일자</th>
              <th>마지막 수정일자</th>
              <th>권한</th>
              <th>액션</th> {/* 잠금 해지 버튼을 위한 열 추가 */}
            </tr>
          </thead>
          <tbody>
            {data.map((v) => {
              return (
                <tr
                  key={v.id}
                  onClick={(e) => handleRowClick(v, e)} // 행을 클릭할 때 handleRowClick 함수 호출
                >
                  <td>{v.id}</td>
                  <td>{v.name}</td>
                  <td>{v.email}</td>
                  <td>{FormatData(DATA_TYPE.DATE, v.createdAt)}</td>
                  <td>{FormatData(DATA_TYPE.DATE, v.updatedAt)}</td>
                  <td>{FormatData(DATA_TYPE.AUTH_ROLE, Number(v.role))}</td>
                  <td>
                    {/* 잠금 해지 버튼 추가 */}
                    {v.status === 1 && (
                      <Button
                        variant="warning"
                        onClick={(e) => {
                          e.stopPropagation(); // 클릭 시 모달이 뜨지 않도록 함
                          handleUnlock(v.id);
                        }}
                      >
                        잠금 해지
                      </Button>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      <div className="d-flex justify-content-end">
        <Button
          variant="dark"
          onClick={(e) => {
            dispatch(
              modalOpen({
                modalType: MODAL_TYPE.ADMIN_REGISTER,
                title: "관리자 계정 추가",
              })
            );
          }}
        >
          관리자 계정 추가
        </Button>
      </div>
    </>
  );
}
