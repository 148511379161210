import React, { useEffect, useState } from "react";
import {
  CODE_SHIPPING_PAYMENT_STATUS,
  CODE_SHOPPING_ORDER_STATUS,
} from "../../../common/constants";
import ShopDetailsModal from "../../../components/modal/ShopDetailsModal";
import { reqAShop } from "../../../requests/shop";
import AdminHeadTitle from "../../../components/AdminHeadTitle";
import styled from "styled-components";
import { Pagination, Table } from "react-bootstrap";
import AdminHeadFilter from "../../../components/AdminHeadFilter";
import { formatDate } from "../../../common/format";

type OrderData = {
  orderId: string;
  userId: string;
  createdAt: string;
  PaymentMethod: number;
  updatedAt: string;
  fancallBirthday: string;
  preorder: boolean;
  fancall: boolean;
  totalPriceUSD: number;
  totalPriceKRW: number;
  status: number;
  productId: string;
};

const AShopDetails: React.FC = () => {
  const [searchCategory, setSearchCategory] = useState("orderId");
  const [allData, setAllData] = useState<OrderData[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [currentOrder, setCurrentOrder] = useState<string | null>(null);
  const [orders, setOrders] = useState<OrderData[]>([]);
  const ITEMS_PER_PAGE = 10;
  const MAX_PAGE_BUTTONS = 10;

  const handleOrderClick = (orderId: string) => {
    setCurrentOrder(orderId);
    setShowModal(true);
  };

  const displayPaymentAmount = (order: OrderData) => {
    switch (order.PaymentMethod) {
      case 1:
        return `${order.totalPriceKRW} KRW`;
      case 2:
        return `${order.totalPriceUSD} USD`;
      default:
        return "-";
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  const fetchOrders = async () => {
    try {
      const response = await reqAShop();
      setOrders(response.data);
      setAllData(response.data);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  const getPaymentMethod = (methodCode: number) => {
    return CODE_SHIPPING_PAYMENT_STATUS[methodCode] || "미결제";
  };

  const getstatusMethod = (methodCode: number) => {
    return CODE_SHOPPING_ORDER_STATUS[methodCode] || "알 수 없음";
  };

  //! 필터 시작
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [searchWord, setSearchWord] = useState<string>("");
  const [searchOption, setSearchOption] = useState<string>("주문 번호");
  const [selectedStatus, setSelectedStatus] = useState("");

  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [currentButtonRange, setCurrentButtonRange] = useState([
    1,
    MAX_PAGE_BUTTONS,
  ]);

  useEffect(() => {
    const thisFilteredData = orders
      .filter((order: any) => {
        if (searchOption === "스페이스 코드") {
          return (
            order.userId.toUpperCase().includes(searchWord.toUpperCase()) &&
            (selectedStatus ? order.status.toString() === selectedStatus : true)
          );
        }
        return (
          order.orderId.toUpperCase().includes(searchWord.toUpperCase()) &&
          (selectedStatus ? order.status.toString() === selectedStatus : true)
        );
      })
      .sort(
        (a: any, b: any) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );
    setFilteredData(thisFilteredData);
    setCurrentPage(1);
    setTotalPages(Math.ceil(thisFilteredData.length / itemsPerPage));
    setCurrentButtonRange([1, Math.min(MAX_PAGE_BUTTONS, totalPages)]);
  }, [
    orders,
    searchWord,
    itemsPerPage,
    selectedStatus,
    searchOption,
    totalPages,
  ]);

  const handleItemsPerPageChange = (newItemsPerPage: number) => {
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
    setCurrentButtonRange([1, Math.min(MAX_PAGE_BUTTONS, totalPages)]);
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    const newStart = Math.max(1, pageNumber - Math.floor(MAX_PAGE_BUTTONS / 2));
    const newEnd = Math.min(totalPages, newStart + MAX_PAGE_BUTTONS - 1);
    setCurrentButtonRange([newStart, newEnd]);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const displayedOrders = filteredData.slice(
    startIndex,
    startIndex + itemsPerPage
  );
  //! 필터 끝

  return (
    <main id="main" className="main">
      <AdminHeadTitle
        subtitle1="구매대행"
        subtitle2="구매대행 거래내역"
        title="구매대행 거래내역"
      />

      {/* 옵션 필터 */}
      <AdminHeadFilter
        selectStatus={(value: string) => setSelectedStatus(value)}
        selectViewPage={(value: number) => handleItemsPerPageChange(value)}
        enterSearch={(value: string) => setSearchWord(value)}
        selectSearchOption={(value: string) => setSearchOption(value)}
      />

      <div className="table-responsive">
        <Table className="table-custom text-center">
          <thead className="table-light">
            <tr>
              <th>주문번호</th>
              <th>담당자</th>
              <th>스페이스코드</th>
              <th>주문날짜</th>
              <th>결제날짜</th>
              <th>결제수단</th>
              <th>결제금액</th>
              <th>주문상태</th>
            </tr>
          </thead>
          <tbody>
            {displayedOrders.map((order: any) => (
              <tr
                key={order.orderId}
                onClick={() => handleOrderClick(order.orderId)}
                style={{ cursor: "pointer" }}
              >
                <th>{order.orderId}</th>
                <th>{order.productId}</th>
                <th>{order.userId}</th>
                <td>{formatDate(order.createdAt)}</td>
                <td>{order.fancallBirthday ? formatDate(order.fancallBirthday) : '미결제'}</td>
                <td>{getPaymentMethod(order.PaymentMethod)}</td>
                <td>{displayPaymentAmount(order)}</td>
                <th>{getstatusMethod(order.status)}</th>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      {/* 페이지 바 */}
      <CenteredPagination style={{ marginTop: "1rem" }}>
        <Pagination.Prev
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        />
        {[...Array(totalPages)].map((_, index) => {
          const pageNumber = index + 1;
          if (
            pageNumber >= currentButtonRange[0] &&
            pageNumber <= currentButtonRange[1]
          ) {
            return (
              <Pagination.Item
                key={pageNumber}
                active={pageNumber === currentPage}
                onClick={() => handlePageChange(pageNumber)}
              >
                {pageNumber}
              </Pagination.Item>
            );
          }
          return null;
        })}
        <Pagination.Next
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        />
      </CenteredPagination>

      {currentOrder && (
        <ShopDetailsModal
          show={showModal}
          onHide={() => setShowModal(false)}
          orderId={currentOrder}
        />
      )}
    </main>
  );
};

const CenteredPagination = styled(Pagination)`
  display: flex;
  justify-content: center;
`;

export default AShopDetails;
