import { AxiosManager } from "../axiosManager";
import { CONST_COOKIE, removeCookie } from "../common/cookies";
import { adminClear } from "../redux/adminSlice";
import { store } from "../redux/store";

export const reqGetAdminAuth = async () => {
  return await AxiosManager.get("admin/auth");
};

export async function reqAdminRefreshAccessToken(refreshToken: any) {
  return await AxiosManager.post(`admin/auth/refresh`, { refreshToken });
}

export const reqAdminLogin = async (payload: any) => {
  return await AxiosManager.post("admin/auth/login", payload);
};

export const reqAdminLogout = async (accessToken: string) => {
  try {
    const result = await AxiosManager.post(
      "admin/auth/logout",
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`, // Authorization 헤더에 액세스 토큰을 포함
        },
      }
    );
    return result;
  } catch (err) {
    console.error("Logout error:", err);
    alert("로그아웃 중 오류가 발생했습니다. 다시 시도해주세요.");
    throw err;
  }
};

export const reqAdminRegister = async (payload: any) => {
  return await AxiosManager.post("admin/auth/register", payload);
};

export const reqAdminUpdate = async (payload: any) => {
  return await AxiosManager.put(`admin/auth/update/${payload.id}`, payload);
};
