import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartOptions,
  Legend,
  LinearScale,
  Tooltip,
} from "chart.js";
import { useEffect, useState } from "react";
import { Bar, Pie } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { CODE_COUNTRY_ITEMS } from "../../common/constants";
import Icon from "../../components/Icon";
import Title from "../../components/Title";
import WorldTimeDisplay from "../../components/WorldTimeDisplay";
import { reqAllAdminBadge } from "../../requests/badge";
import { reqGetAllUserById } from "../../requests/user";

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend
);

interface User {
  membershipId: string;
  country: number;
}

interface ChartData {
  labels: string[];
  datasets: {
    label: string;
    data: number[];
    backgroundColor: string[];
    borderColor: string[];
    borderWidth: number;
  }[];
}
interface ChartSData {
  labels: string[];
  datasets: {
    label: string;
    data: number[];
    backgroundColor: string;
    borderColor: string;
    borderWidth: number;
  }[];
}

const initialMembershipData: ChartData = {
  labels: [],
  datasets: [
    {
      label: "",
      data: [],
      backgroundColor: [],
      borderColor: [],
      borderWidth: 1,
    },
  ],
};

export default function ADashboard() {
  const nav = useNavigate();
  const admin = useSelector((state: any) => state.admin);
  const membershipCounts: { [key: string]: number } = {
    "1": 0,
    "2": 0,
    "3": 0,
  };
  const [membershipData, setMembershipData] = useState<ChartData>(
    initialMembershipData
  );

  // Badge
  const [badgeList, setBadgeList] = useState<any>([]);

  const fetchBadgeList = async () => {
    const res = await reqAllAdminBadge();
    setBadgeList(res.data);
    return res.data;
  };

  useEffect(() => {
    if (!admin.accessToken) {
      // 액세스 토큰이 없을 경우 로그인 페이지로 이동
      nav("/admin/login");
    }
  }, [admin.accessToken, nav]);

  useEffect(() => {
    fetchAndPrepareMembershipData().then((data) => {
      if (data) {
        setMembershipData(data);
      }
    });
    fetchBadgeList();
  }, []);

  const [countryData, setCountryData] = useState<ChartSData>({
    labels: [],
    datasets: [
      {
        label: "",
        data: [],
        backgroundColor: "",
        borderColor: "",
        borderWidth: 1,
      },
    ],
  });

  useEffect(() => {
    fetchAndAggregateCountryData().then((countryCounts) => {
      const chartData = prepareCountryChartData(countryCounts);
      setCountryData(chartData);
    });
  }, []);

  if (admin.id <= 0 || !admin.id) {
    return (
      <>
        <Title title="Dashboard" />
        <div>
          <p>
            관리자 권한이 필요합니다.
            <br />
            로그인 후 이용해 주십시오.
          </p>
        </div>
      </>
    );
  }

  async function fetchAndPrepareMembershipData() {
    try {
      const response = await reqGetAllUserById();
      const users: User[] = response.data;

      const membershipCounts = { "1": 0, "2": 0, "3": 0 };
      users.forEach((user: User) => {
        const membershipId = user.membershipId;
        if (membershipCounts.hasOwnProperty(membershipId)) {
          membershipCounts[membershipId as keyof typeof membershipCounts]++;
        }
      });

      const membershipData = prepareChartData(membershipCounts);
      return membershipData;
    } catch (error) {
      console.error("Error fetching membership data:", error);
    }
  }

  async function fetchAndAggregateCountryData() {
    try {
      const response = await reqGetAllUserById();
      const users: User[] = response.data;

      // 국가별 사용자 수 집계
      const countryCounts: { [key: number]: number } = {};
      users.forEach(({ country }) => {
        countryCounts[country] = (countryCounts[country] || 0) + 1;
      });

      return countryCounts;
    } catch (error) {
      console.error("Error fetching user data:", error);
      return {};
    }
  }

  function prepareCountryChartData(countryCounts: {
    [key: number]: number;
  }): ChartSData {
    const sortedCountryCounts = Object.entries(countryCounts)
      .sort(([, a], [, b]) => b - a)
      .slice(0, 10);

    const labels = sortedCountryCounts.map((key) =>
      typeof CODE_COUNTRY_ITEMS[+key[0]] === "string"
        ? CODE_COUNTRY_ITEMS[+key[0]]
        : "Unknown"
    );
    const data = sortedCountryCounts.map((key) => key[1]);

    return {
      labels: labels as string[],
      datasets: [
        {
          label: "Member Count by Country",
          data,
          backgroundColor: "rgba(54, 162, 235, 0.2)",
          borderColor: "rgba(54, 162, 235, 1)",
          borderWidth: 1,
        },
      ],
    };
  }

  function prepareChartData(membershipCounts: { [key: string]: number }) {
    return {
      labels: ["Red", "Blue", "Green"],
      datasets: [
        {
          label: "Membership Status",
          data: [
            membershipCounts["1"],
            membershipCounts["2"],
            membershipCounts["3"],
          ],
          backgroundColor: [
            "rgba(255, 99, 132, 0.2)", // Red
            "rgba(54, 162, 235, 0.2)", // Blue
            "rgba(75, 192, 192, 0.2)", // Green
          ],
          borderColor: [
            "rgba(255, 99, 132, 1)", // Red
            "rgba(54, 162, 235, 1)", // Blue
            "rgba(75, 192, 192, 1)", // Green
          ],
          borderWidth: 1,
        },
      ],
    };
  }

  const options: ChartOptions<"bar"> = {
    indexAxis: "y",
  };

  const sections = [
    {
      title: "구매대행 서비스",
      links: [
        {
          path: "/admin/shop/order",
          label: "요청서",
          badgeName: "SHOP_REQUEST_FORM",
        },
        {
          path: "/admin/shop/pay/wise",
          label: "결제 대기중",
          badgeName: "SHOP_PAYMENT_WAITING",
        },
        {
          path: "/admin/shop/paid",
          label: "결제 완료",
          badgeName: "-",
        },
        {
          path: "/admin/shop/details",
          label: "거래 내역",
          badgeName: "-",
        },
        // {
        //   path: "/admin/shop/expired",
        //   label: "결제 기한 만료",
        //   badgeName: "SHOP_REQUEST_CANCEL",
        // },
      ],
      colorClass: "bg-primary",
    },
    {
      title: "배송대행 서비스",
      links: [
        {
          path: "/admin/ship/order",
          label: "요청서",
          badgeName: "SHIP_REQUEST_FORM",
        },
        {
          path: "/admin/ship/pay",
          label: "결제 대기중",
          badgeName: "SHIP_PAYMENT_WAITING",
        },
        {
          path: "/admin/ship/expired",
          label: "결제 기한 만료",
          badgeName: "-",
        },
        { path: "/admin/ship/paid", label: "결제 완료", badgeName: "-" },
      ],
      colorClass: "bg-warning",
    },
    {
      title: "창고 관리",
      links: [
        {
          path: "/admin/warehouse/checkcontents",
          label: "체크 컨텐츠",
          badgeName: "CHECK_CONTENT",
        },
        {
          path: "/admin/warehouse/findlost",
          label: "분실물 색출",
          badgeName: "LOST_PRODUCT",
        },
        {
          path: "/admin/warehouse/expired",
          label: "무료기한 만료",
          badgeName: "FREE_EXPIRE",
        },
        {
          path: "/admin/warehouse/split",
          label: "스플릿",
          badgeName: "SPLIT",
        },
      ],
      colorClass: "bg-warning",
    },
    {
      title: "고객 서비스 관리",
      links: [
        { path: "/admin/service/qna", label: "고객 문의", badgeName: "QNA" },
        { path: "/admin/service/review", label: "리뷰", badgeName: "REVIEW" },
        // {
        //   path: "/admin/space",
        //   label: "스페이스코드 변경 요청",
        //   badgeName: "SPACE",
        // },
      ],
      colorClass: "bg-warning",
    },
  ];

  const cities = [
    { name: "런던", timeZone: "Europe/London" },
    { name: "모스크바", timeZone: "Europe/Moscow" },
    { name: "파리", timeZone: "Europe/Paris" },
    { name: "베이징", timeZone: "Asia/Hong_Kong" },
    { name: "두바이", timeZone: "Asia/Dubai" },
    { name: "케이프타운", timeZone: "Europe/Helsinki" },
    { name: "서울", timeZone: "Asia/Seoul" },
    { name: "자카르타", timeZone: "Asia/Jakarta" },
    { name: "시드니", timeZone: "Australia/Sydney" },
    { name: "벤쿠버", timeZone: "America/Vancouver" },
    { name: "뉴욕", timeZone: "America/New_York" },
    { name: "로스엔젤레스", timeZone: "America/Los_Angeles" },
    { name: "멕시코시티", timeZone: "America/Mexico_City" },
    { name: "상파울루", timeZone: "America/Sao_Paulo" },
  ];

  return (
    <DashboardContainer>
      <main>
        <Title title="Dashboard" />
        <div className="container-fluid">
          <div className="row">
            {sections.map((section, index) => (
              <div className="col-xl-3 col-md-6" key={index}>
                <div
                  className={`card mb-4`}
                  style={{
                    backgroundColor: section.colorClass,
                    borderColor: "rgba(255, 255, 255, 0.125)",
                  }}
                >
                  <div
                    className="card-header"
                    style={{ backgroundColor: "rgba(255, 255, 255, 0.03)" }}
                  >
                    {section.title}
                  </div>
                  {section.links.map((link, linkIndex) => (
                    <div
                      key={linkIndex}
                      className="card-footer d-flex align-items-center"
                      onClick={() => nav(link.path)}
                      style={{
                        cursor: "pointer",
                        backgroundColor: "rgba(0, 0, 0, 0.03)",
                      }}
                    >
                      {badgeList.includes(link.badgeName) && (
                        <Icon
                          icon="exclamation-circle-fill"
                          fontSize="1.4rem"
                          color="var(--color-main-red)"
                          style={{ marginRight: "0.4rem" }}
                        />
                      )}

                      <div>{link.label}</div>

                      {/* {link.badgeName && <span className="badge bg-danger ms-auto">New</span>} */}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>

          <div className="f-row">
            {/* 통계 */}
            <div style={{ width: "40%" }}>
              <div>
                <h3>멤버십 현황</h3>
                <div className="f-center">
                  <Pie data={membershipData} id="pie-chart" />
                </div>
              </div>

              <div style={{ marginTop: "3rem" }}>
                <h3>국가 별 사용자 분포</h3>
                <div className="f-center">
                  <Bar data={countryData} options={options} />
                </div>
              </div>
            </div>

            {/* 세계 시간 */}
            <div style={{ width: "55%", marginLeft: "5%" }}>
              <h3>세계 시간</h3>
              <Grid>
                {cities.map((city) => (
                  <GridItem
                    style={{ width: "20%", textAlign: "center" }}
                    className="f-center"
                  >
                    <WorldTimeDisplay
                      key={city.timeZone}
                      city={city.name}
                      timeZone={city.timeZone}
                    />
                  </GridItem>
                ))}
              </Grid>
            </div>
          </div>
        </div>
      </main>
    </DashboardContainer>
  );
}

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const GridItem = styled.div`
  width: 30% !important;
  text-align: center;
  margin-bottom: 20px;
  border: 1px solid lightgray;
  border-radius: 10rem;
  padding: 0.4rem;
`;

const DashboardContainer = styled.div`
  .card {
    margin-bottom: 20px;
    border: none;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    .card-header {
      background-color: #fff;
      font-size: 1.25rem;
      color: #333;
    }

    .card-footer {
      background-color: #f8f9fa;
      color: #333;
    }
  }

  .chart-container {
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin-top: 20px;
  }

  h3 {
    margin-bottom: 20px;
    font-weight: 600;
    color: #333;
  }

  #pie-chart {
    width: 56% !important;
    height: 56% !important;
  }
`;
