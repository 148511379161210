import React, { useEffect, useState } from "react";
import { Pagination } from "react-bootstrap";
import styled from "styled-components";
import { formatDate } from "../../../common/format";
import AdminHeadFilter from "../../../components/AdminHeadFilter";
import AdminHeadTitle from "../../../components/AdminHeadTitle";
import ShopDetailsModal from "../../../components/modal/ShopDetailsModal";
import { reqAShop } from "../../../requests/shop";

type OrderData = {
  orderId: string;
  userId: string;
  createdAt: string;
  paymentDeadline: string;
  user: {
    name: string;
    membershipId: number;
  };
  status: number;
};

const AShopCancel: React.FC = () => {
  const [searchCategory, setSearchCategory] = useState("orderId");
  const [allData, setAllData] = useState<OrderData[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [currentOrder, setCurrentOrder] = useState<string | null>(null);
  const [orders, setOrders] = useState<OrderData[]>([]);
  const ITEMS_PER_PAGE = 10;

  const handleOrderClick = (orderId: string) => {
    setCurrentOrder(orderId);
    setShowModal(true);
  };

  const handleSelectClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const displayMembershipLevel = (membershipId: number) => {
    switch (membershipId) {
      case 1:
        return "RED";
      case 2:
        return "BLUE";
      case 3:
        return "GREEN";
      default:
        return "알 수 없음";
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  const fetchOrders = async () => {
    try {
      const response = await reqAShop();
      // status가 2인 주문만 필터링
      const filteredOrders = response.data.filter(
        (order: OrderData) => order.status === 2
      );
      setOrders(filteredOrders);
      setAllData(filteredOrders);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [searchWord, setSearchWord] = useState<string>("");
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    const thisFilteredData = orders
      .filter((order: any) =>
        order.orderId.toUpperCase().includes(searchWord.toUpperCase())
      )
      .sort(
        (a: any, b: any) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );
    setFilteredData(thisFilteredData);
    setCurrentPage(1);
    setTotalPages(Math.ceil(thisFilteredData.length / itemsPerPage));
  }, [orders, searchWord, itemsPerPage]);

  const handleItemsPerPageChange = (newItemsPerPage: number) => {
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const displayedOrders = filteredData.slice(
    startIndex,
    startIndex + itemsPerPage
  );

  return (
    <main id="main" className="main">
      <AdminHeadTitle
        subtitle1="구매대행"
        subtitle2="구매대행 결제 기한 만료"
        title="구매대행 결제 기한 만료 내역"
      />

      {/* 옵션 필터 */}
      <AdminHeadFilter
        selectViewPage={(value: number) => handleItemsPerPageChange(value)}
        enterSearch={(value: string) => setSearchWord(value)}
      />

      <div className="container-fluid">
        <table className="table table-hover text-center">
          <thead>
            <tr>
              <th>주문번호</th>
              <th>스페이스코드</th>
              <th>주문날짜</th>
              <th>결제마감일</th>
              <th>성명</th>
              <th>멤버십 등급</th>
            </tr>
          </thead>
          <tbody>
            {displayedOrders.map((order: any) => (
              <tr
                key={order.orderId}
                onClick={() => handleOrderClick(order.orderId)}
                style={{ cursor: "pointer" }}
              >
                <th>{order.orderId}</th>
                <th>{order.userId}</th>
                <td>{formatDate(order.createdAt)}</td>
                <td>{formatDate(order.paymentDeadline)}</td>
                <td>{order.user?.name || '사용자 정보 없음'}</td>
                <td>{order.user?.membershipId ? displayMembershipLevel(order.user.membershipId) : '회원 정보 없음'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* 페이지 바 */}
      <CenteredPagination style={{ marginTop: "1rem" }}>
        {[...Array(totalPages)].map((_, index) => (
          <Pagination.Item
            key={index}
            active={index + 1 === currentPage}
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </Pagination.Item>
        ))}
      </CenteredPagination>

      {currentOrder && (
        <ShopDetailsModal
          show={showModal}
          onHide={() => setShowModal(false)}
          orderId={currentOrder}
        />
      )}
    </main>
  );
};

const CenteredPagination = styled(Pagination)`
  display: flex;
  justify-content: center;
`;

export default AShopCancel;
