import axios from "axios";
import { useEffect, useState } from "react";
import { Pagination, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  ACODE_SHOPING_ORDER_STATUS,
  ADMIN_BADGE,
  MEMBERSHIP,
} from "../../../common/constants";
import { formatDate } from "../../../common/format";
import AdminHeadFilter from "../../../components/AdminHeadFilter";
import AdminHeadTitle from "../../../components/AdminHeadTitle";
import { getColor } from "../../../components/getColor";
import RepackModal from "../../../components/modal/RepackModalProps";
import { reqReadAdminBadge } from "../../../requests/badge";
import { reqAllShippay } from "../../../requests/ship";
const ITEMS_PER_PAGE = 10;

export default function AShipPay() {
  const nav = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [showRepackModal, setShowRepackModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<string | null>(null);
  const [data, setData] = useState<OrderData[]>([]);

  type OrderData = {
    id: string;
    createdAt: string;
    estimatedAt: string;
    expiredAt: string;
    userId: string;
    adrReceiver: string;
    status: number;
    membershipGrade: string;
    manager: string;
    express: string;
    user: {
      name: string;
      membershipId: number;
    };
  };

  const handleStatusClick = (orderId: string) => {
    setSelectedOrder(orderId);
    setShowModal(true);
  };

  const fetchData = async () => {
    try {
      const response = await reqAllShippay(); // reqAllShippay 함수 사용
      const filteredData = response.data.filter(
        (item: OrderData) =>
          item.status === ACODE_SHOPING_ORDER_STATUS.PAID ||
          item.status === ACODE_SHOPING_ORDER_STATUS.PRODUCT_PART
      );
      setData(filteredData); // 필터링된 데이터를 상태로 저장
    } catch (error) {
      console.error("Error fetching data: ", error);
      nav("/admin/login"); // 오류 발생 시 로그인 페이지로 리다이렉트
    }
  };

  useEffect(() => {
    fetchData(); // 컴포넌트 마운트 시 데이터 요청
  }, []);

  // // 로컬 스토리지에서 엑세스 토큰을 가져오는 함수
  // const getAccessTokenFromLocalStorage = () => {
  //   const state = localStorage.getItem("state");
  //   if (state) {
  //     const parsedState = JSON.parse(state);
  //     return parsedState?.admin?.accessToken || null;
  //   }
  //   return null;
  // };

  // // 데이터 가져오기 (엑세스 토큰 포함)
  // const fetchData = async () => {
  //   const accessToken = getAccessTokenFromLocalStorage(); // 액세스 토큰 가져오기

  //   if (!accessToken) {
  //     console.error("No access token found");
  //     nav("/admin/login"); // 토큰이 없으면 로그인 페이지로 리다이렉트
  //     return;
  //   }

  //   try {
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_BASE_URL}ship/ontact`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${accessToken}`, // 액세스 토큰을 헤더에 추가
  //         },
  //       }
  //     );
  //     const filteredData = response.data.filter(
  //       (item: OrderData) =>
  //         item.status === ACODE_SHOPING_ORDER_STATUS.PAID ||
  //         item.status === ACODE_SHOPING_ORDER_STATUS.PRODUCT_PART
  //     );
  //     setData(filteredData);
  //   } catch (error) {
  //     console.error("Error fetching data: ", error);
  //     nav("/admin/login"); // 오류 발생 시 로그인 페이지로 이동
  //   }
  // };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.get(
  //         `${process.env.REACT_APP_BASE_URL}ship/ontact`
  //       );
  //       const filteredData = response.data.filter(
  //         (item: OrderData) =>
  //           item.status === ACODE_SHOPING_ORDER_STATUS.PAID ||
  //           item.status === ACODE_SHOPING_ORDER_STATUS.PRODUCT_PART
  //       );
  //       setData(filteredData);
  //     } catch (error) {
  //       console.error("Error fetching data: ", error);
  //     }
  //   };
  //   fetchData();
  // }, []);

  const refreshData = () => {
    fetchData(); // 데이터 새로고침
  };

  useEffect(() => {
    fetchData();

    const badgePayload = {
      badgeName: ADMIN_BADGE.SHIP_PAYMENT_WAITING,
    };
    reqReadAdminBadge(badgePayload);
  }, []);

  const handleRepackRequest = () => {
    setShowModal(false);
    setShowRepackModal(true);
    fetchData();
  };

  //! 필터 시작
  const [filteredData, setFilteredData] = useState<any[]>([]);

  const [searchWord, setSearchWord] = useState<string>("");
  const [searchOption, setSearchOption] = useState<string>("");

  const [selectedStatus, setSelectedStatus] = useState("");

  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    const thisFilteredData = data
      .filter((order: any) =>
        order.id.toUpperCase().includes(searchWord.toUpperCase())
      )
      .sort((a: any, b: any) => {
        if (a.express === "입금 확인 필요" && b.express !== "입금 확인 필요") {
          return -1;
        }
        if (a.express !== "입금 확인 필요" && b.express === "입금 확인 필요") {
          return 1;
        }
        return (
          new Date(a.expiredAt).getTime() - new Date(b.expiredAt).getTime()
        );
      });

    setFilteredData(thisFilteredData);
    setCurrentPage(1);
    setTotalPages(Math.ceil(thisFilteredData.length / itemsPerPage));
  }, [data, searchWord, itemsPerPage, selectedStatus]);

  const handleItemsPerPageChange = (newItemsPerPage: number) => {
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const displayedOrders = filteredData.slice(
    startIndex,
    startIndex + itemsPerPage
  );
  //! 필터 끝

  const tableRows = displayedOrders.map((item, index) => {
    const createdAtFormatted = item.createdAt
      ? formatDate(item.createdAt)
      : "N/A";
    const estimatedAtFormatted =
      item.estimatedAt && !isNaN(new Date(item.estimatedAt).getTime())
        ? formatDate(item.estimatedAt)
        : "N/A";
    const expiredAtFormatted =
      item.expiredAt && !isNaN(new Date(item.expiredAt).getTime())
        ? formatDate(item.expiredAt)
        : "N/A";

    return (
      <tr key={index}>
        <td>{index + 1 + startIndex}</td>
        <td>{item.id}</td>
        <td>{createdAtFormatted}</td>
        <td>{estimatedAtFormatted}</td>
        <td>{expiredAtFormatted}</td>
        <td>{item.userId}</td>
        <td>{item.user.name}</td>
        <td style={{ color: getColor(item.user.membershipId) }}>
          {MEMBERSHIP[item.user.membershipId]}
        </td>
        <td>{item.manager}</td>
        <td>{item.express === "" ? "임금 대기" : item.express}</td>
        <td>
          <button
            className="btn btn-primary btn-sm"
            onClick={() => handleStatusClick(item.id)}
          >
            {item.status === ACODE_SHOPING_ORDER_STATUS.PRODUCT_PART
              ? "완료"
              : "요청서 확인"}
          </button>
        </td>
      </tr>
    );
  });

  const handleKeyPress = (event: any) => {
    if (event.key === " ") {
      event.preventDefault();
    }
  };

  return (
    <div>
      <AdminHeadTitle
        subtitle1="배송대행"
        subtitle2="배송대행 결제 대기중"
        title="배송대행 결제 대기중"
      />

      {/* 옵션 필터 */}
      <AdminHeadFilter
        selectViewPage={(value: number) => handleItemsPerPageChange(value)}
        enterSearch={(value: string) => setSearchWord(value)}
        selectSearchOption={(value: string) => setSearchOption(value)}
      />

      <div className="table-responsive">
        <Table className="table-custom text-center">
          <thead className="table-light">
            <tr>
              <th>No</th>
              <th>주문 번호</th>
              <th>주문 날짜</th>
              <th>견적서 전송 날짜</th>
              <th>결제 마감일</th>
              <th>스페이스 코드</th>
              <th>성명</th>
              <th>멤버쉽 등급</th>
              <th>담당자</th>
              <th>입금 상태</th>
              <th>상태</th>
            </tr>
          </thead>
          <tbody>{tableRows}</tbody>
        </Table>
      </div>

      {/* 페이지 바 */}
      <CenteredPagination style={{ marginTop: "1rem" }}>
        {[...Array(totalPages)].map((_, index) => (
          <Pagination.Item
            key={index}
            active={index + 1 === currentPage}
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </Pagination.Item>
        ))}
      </CenteredPagination>

      {showModal && (
        <RepackModal
          show={showModal}
          onHide={() => setShowModal(false)}
          orderNumber={selectedOrder ?? ""}
          onRepackRequest={handleRepackRequest}
          refreshData={refreshData}
        />
      )}
    </div>
  );
}

const CenteredPagination = styled(Pagination)`
  display: flex;
  justify-content: center;
`;

const TableContainer = styled.div`
  width: 100%;
  margin-top: 40px;
  margin-bottom: 40px;

  table {
    border-collapse: collapse;

    th,
    td {
      padding: 8px;
      text-align: center;
      border: 1px solid #ddd;
    }

    th {
      background-color: #f2f2f2;
    }
  }
`;
const ResponsiveTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  table-layout: auto;

  th,
  td {
    padding: 8px;
    text-align: center;
    border: 1px solid #ddd;
  }

  @media (max-width: 768px) {
    font-size: 0.8em;
    th,
    td {
      padding: 6px;
    }
  }

  @media (max-width: 480px) {
    font-size: 0.7em;
    th,
    td {
      padding: 4px;
    }
  }
`;

const Toolbar = styled.div`
  .right {
    display: flex;
    gap: 10px;
    width: 300px;
    float: right;

    button {
      width: 100px;
    }
  }

  .left {
    display: flex;
    gap: 10px;

    > div {
      width: 150px;

      button {
        border-radius: 20px;
      }
    }
  }
`;
