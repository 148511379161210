import { useEffect, useRef, useState } from "react";
import { FormCheck, FormControl, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { styled } from "styled-components";
import Swal from "sweetalert2";
import {
  ADMIN_BADGE,
  AUTO_RECEIVE_CONDITION,
  CODE_COUNTRY_ITEMS,
  INFLOW_SOURCES,
  INTERNATIONAL,
} from "../../common/constants";
import Icon from "../../components/Icon";
import { MODAL_TYPE } from "../../components/modal/GlobalModal";
import { modalOpen } from "../../redux/modalSlice";
import { reqSendAlarm } from "../../requests/alarm";
import {
  reqRegisterConfirmCode,
  reqRegisterSendCode,
  reqUserRegister,
} from "../../requests/auth";
import { reqAddAdminBadge } from "../../requests/badge";
import { reqCouponList, reqSendCoupon } from "../../requests/coupon";
import { reqGetUserById } from "../../requests/user";

export default function SignUp() {
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.user);
  const nav = useNavigate();

  const time = useRef(120);
  const timerId = useRef<any>(null);
  const [confirmTimer, setConfirmTimer] = useState<number>(120);
  const [mailConfirmed, setMailConfirmed] = useState<boolean>(false);
  const [activeConfirmCode, setActiveConfirmCode] = useState<boolean>(false);
  const [showOldIdInput, setShowOldIdInput] = useState<boolean>(false);
  const [form, setForm] = useState<{
    firstName: string;
    lastName: string;
    email: string;
    confirmCode: string;
    password: string;
    confirmPassword: string;
    phone: string;
    international: string;
    oldid: string;
    option1: string;
    option2: string;
    option3: string;
    state: string;
    city: string;
    country: number;
    zip: string;
    favorite: string;
    inflow: number;
    agreeAll: boolean;
    agreeAge: boolean;
    agreePolicy: boolean;
    agreeMarketing: boolean;
    existingUser: boolean;
    recommandedPerson: string;
    Check: boolean;
  }>({
    firstName: "",
    lastName: "",
    email: "",
    confirmCode: "",
    password: "",
    confirmPassword: "",
    phone: "",
    international: "",
    oldid: "",
    option1: "",
    option2: "",
    option3: "",
    state: "",
    city: "",
    country: 0,
    zip: "",
    favorite: "",
    inflow: 0,
    agreeAll: false,
    agreeAge: false,
    agreePolicy: false,
    agreeMarketing: false,
    existingUser: false,
    recommandedPerson: "",
    Check: false,
  });

  const handleOldIdCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { checked } = e.target;
    setShowOldIdInput(checked);
    setForm((currentForm) => ({
      ...currentForm,
      existingUser: checked,
      Check: checked,
    }));

    if (checked) {
      dispatch(
        modalOpen({
          modalType: MODAL_TYPE.INFO,
          title: "Important Notice",
          payload: {
            text: "All features will be unavailable until the space code is updated. All features will be available to administrators once the space code is updated.",
          },
        })
      );
    }
  };

  const countryOptions = Object.entries(CODE_COUNTRY_ITEMS).map(
    ([key, value]) => ({
      value: key,
      label: value,
    })
  );

  const customSelectStyles = {
    control: (provided: any) => ({
      ...provided,
      marginBottom: "10px",
    }),
  };

  const handleCountrySelect = (selectedKey: any) => {
    setForm({ ...form, country: Number(selectedKey) });
  };

  const handleInternationalCodeSelect = (selectedOption: any) => {
    if (selectedOption) {
      setForm({ ...form, international: String(selectedOption.value) });
    } else {
      console.log("No international code selected");
    }
  };

  useEffect(() => {
    timerId.current = setInterval(() => {
      if (time.current > 0 && activeConfirmCode) {
        time.current -= 1;
        setConfirmTimer(time.current);
      } else if (time.current <= 0) {
        setActiveConfirmCode(false);
      }
    }, 1000);
    //
    return () => clearInterval(timerId.current);
  }, [activeConfirmCode]);

  const Toast = Swal.mixin({
    toast: true,
    position: "center",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const options = [
    { value: INFLOW_SOURCES.GOOGLE, label: "GOOGLE" },
    { value: INFLOW_SOURCES.INSTAGRAM, label: "INSTAGRAM" },
    { value: INFLOW_SOURCES.TIKTOK, label: "TIKTOK" },
    { value: INFLOW_SOURCES.WATCHA, label: "WhatsApp" },
    { value: INFLOW_SOURCES.KAKAOTALK, label: "KAKAOTALK" },
    { value: INFLOW_SOURCES.SEARCH, label: "SEARCH" },
    { value: INFLOW_SOURCES.LINE, label: "LINE" },
    { value: INFLOW_SOURCES.OTHER, label: "OTHER" },
  ];

  const verifyForm = () => {
    let [sign, message] = [false, ""];
    const {
      firstName,
      lastName,
      email,
      password,
      confirmPassword,
      phone,
      option1,
      option2,
      state,
      city,
      country,
      zip,
      agreePolicy,
      agreeAge,
      agreeMarketing,
      existingUser,
      oldid,
    } = form;

    const emailRegex = /^[^\s@]+@[^\s@]+$/;
    const noAccentRegex = /^[\x00-\x7F]*$/;

    if (!firstName) {
      message = "First name is required.";
    } else if (!lastName) {
      message = "Last name is required.";
    } else if (!emailRegex.test(email)) {
      message = "Invalid email format.";
    } else if (password.length < 6) {
      message = "Password must be at least 6 characters long.";
    } else if (password !== confirmPassword) {
      message = "Passwords do not match.";
    } else if (!phone) {
      message = "Phone number is required.";
    } else if (country === 0) {
      message = "Country is required.";
    } else if (existingUser && !oldid) {
      message =
        "Current space (starting with WH) is required for existing users.";
    } else if (!mailConfirmed) {
      message = "Email confirmation is required.";
    } else if (option1.length > 35 || option2.length > 35) {
      message = "Option fields must be less than 35 characters.";
    } else if (!noAccentRegex.test(option1) || !noAccentRegex.test(option2)) {
      message = "Option fields cannot contain accents.";
    } else {
      sign = true;
    }
    return { sign, message };
  };

  const [agreements, setAgreements] = useState({
    agreeAll: false,
    agreeTerms: false,
    agreeAge: false,
    agreeMarketing: false,
  });

  const handleAgreeAllChange = (event: any) => {
    const { checked } = event.target;
    setAgreements({
      agreeAll: checked,
      agreeTerms: checked,
      agreeAge: checked,
      agreeMarketing: checked,
    });
    setForm((currentForm) => ({
      ...currentForm,
      agreeTerms: checked,
      agreeAge: checked,
      agreeMarketing: checked,
    }));
  };

  const handleIndividualAgreementChange =
    (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target;
      setAgreements((prevAgreements) => ({
        ...prevAgreements,
        [field]: checked,
      }));
      setForm((currentForm) => ({
        ...currentForm,
        [field]: checked,
      }));
    };

  const 회원가입_직전_자동지급_쿠폰이_있다면_지급 = async (
    myUserId: string,
    recommandUserId: string
  ) => {
    const couponList = await reqCouponList();
    couponList.data.map(async (coupon: any) => {
      if (coupon.isAutoReceive) {
        if (
          coupon.autoReceiveCondition === AUTO_RECEIVE_CONDITION["REGISTER"]
        ) {
          await reqSendCoupon({
            userIdList: [myUserId],
            couponId: coupon.id,
          });
          await reqSendAlarm({
            userId: myUserId,
            read: 0,
            content: `Register event coupon has been given.`,
            sender: `ontactkorea`,
          });
        } else if (
          coupon.autoReceiveCondition ===
          AUTO_RECEIVE_CONDITION["ADD_RECOMMAND"]
        ) {
          await reqSendCoupon({
            userIdList: [myUserId],
            couponId: coupon.id,
          });
          await reqSendAlarm({
            userId: myUserId,
            read: 0,
            content: `Recommanded person event coupon has been given.`,
            sender: `ontactkorea`,
          });
        } else if (
          coupon.autoReceiveCondition ===
          AUTO_RECEIVE_CONDITION["RECEIVE_RECOMMAND"]
        ) {
          if (recommandUserId === "") return;
          await reqSendCoupon({
            userIdList: [recommandUserId],
            couponId: coupon.id,
          });
          await reqSendAlarm({
            userId: recommandUserId,
            read: 0,
            content: `Recommanded person event coupon has been given.`,
            sender: `ontactkorea`,
          });
        }
      }
    });
  };

  const checkExistSpaceCode = async (userId: string): Promise<boolean> => {
    const res = await reqGetUserById({ id: userId });
    return res.data ? true : false;
  };

  const handleKeyPress = (event: any) => {
    if (event.key === " ") {
      event.preventDefault();
    }
  };

  const [loading, setLoading] = useState(false);

  return (
    <>
      <SignUpBox>
        <Inner>
          <h1 className="mb-4">Sign Up</h1>
          <p className="label-large-signup">Name *</p>
          <div className="input-box">
            <FormControl
              placeholder="First name"
              value={form.firstName}
              onChange={(e) => {
                setForm({ ...form, firstName: e.target.value });
              }}
            />
            <FormControl
              placeholder="Last name"
              value={form.lastName}
              onChange={(e) => {
                setForm({ ...form, lastName: e.target.value });
              }}
            />
          </div>
          <p className="label-large-signup">Email *</p>
          <div className="input-box">
            <div style={{ position: "relative", width: "100%" }}>
              <FormControl
                placeholder="Email"
                value={form.email}
                onKeyPress={handleKeyPress}
                onChange={(e) => {
                  setForm({ ...form, email: e.target.value });
                }}
              />
              {mailConfirmed && (
                <Icon
                  icon="check-circle"
                  color="var(--color-main-blue)"
                  fontSize="23px"
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "3px",
                    zIndex: 888,
                  }}
                />
              )}
            </div>
            <SendCodeButton
              style={{ width: "300px" }}
              onClick={async (e) => {
                setActiveConfirmCode(true);
                await reqRegisterSendCode({ email: form.email });
                Swal.fire({
                  title: "Precautions",
                  html: "Confirm code sent to this Email.<br/>Please check your email.<br/>It takes time to send an e-mail, so Please wait a little bit.</span>",
                  icon: "info",
                  confirmButtonText: "OK",
                });
                const button = e.target as HTMLButtonElement;
                button.disabled = true;
                setTimeout(() => {
                  button.disabled = false;
                }, 120000);
              }}
            >
              Send confirm code
            </SendCodeButton>
          </div>
          <div className="input-box" style={{ position: "relative" }}>
            <FormControl
              placeholder="Enter confirm code"
              value={form.confirmCode}
              onChange={(e) => {
                setForm({ ...form, confirmCode: e.target.value });
              }}
              disabled={!activeConfirmCode}
            />
            <div
              style={{
                width: "70px",
                textAlign: "center",
                backgroundColor: "#eee",
                borderRadius: "10px",
                position: "absolute",
                right: "10px",
              }}
            >
              {Math.floor(confirmTimer / 60)}:
              {confirmTimer % 60 < 10 ? "0" : ""}
              {confirmTimer % 60}
            </div>
          </div>
          <ConfirmButton
            style={{ width: "100%", display: "block", marginBottom: "15px" }}
            disabled={!activeConfirmCode}
            onClick={async (e) => {
              try {
                const result = await reqRegisterConfirmCode({
                  email: form.email,
                  code: form.confirmCode,
                });
                setMailConfirmed(result.data ?? false);
                if (result.data) {
                  Swal.fire({
                    title: "Info",
                    text: "Email confirmation success",
                    icon: "success",
                    confirmButtonText: "OK",
                  });
                } else {
                  throw new Error("Confirmation Code is not correct.");
                }
              } catch (err) {
                Swal.fire({
                  title: "Confirmation Failed",
                  text: "Confirmation Code is not correct.",
                  icon: "error",
                  confirmButtonText: "OK",
                });
              }
            }}
          >
            Confirm Email
          </ConfirmButton>

          <p className="label-large-signup">Password *</p>
          <div className="input-box" style={{ marginBottom: "10px" }}>
            <FormControl
              type="password"
              placeholder="Password"
              onKeyPress={handleKeyPress}
              value={form.password}
              onChange={(e) => {
                setForm({ ...form, password: e.target.value });
              }}
            />
          </div>
          <span
            style={{
              fontSize: "small",
              display: "block",
              marginBottom: "15px",
            }}
          >
            Password must be at least 6 characters in length.
          </span>

          <p className="label-large-signup" style={{ marginBottom: "10px" }}>Confirm Password *</p>
          <div className="input-box">
            <FormControl
              type="password"
              placeholder="Password Again"
              onKeyPress={handleKeyPress}
              value={form.confirmPassword}
              onChange={(e) => {
                setForm({ ...form, confirmPassword: e.target.value });
              }}
            />
          </div>
          <p className="label-large-signup" style={{ marginBottom: "10px" }} >Country *</p>
          <Select
            options={countryOptions}
            styles={{
              ...customSelectStyles,
              container: (provided) => ({
                ...provided,
                flex: 2,
              }),
            }}
            onChange={(selectedOption) => {
              if (selectedOption !== null) {
                handleCountrySelect(selectedOption.value);
              } else {
                console.log("No country selected");
              }
            }}
            placeholder="Select Country *"
          />
          <p className="label-large-signup" style={{ marginBottom: "10px" }}>Phone Number *</p>
          <Select
            options={INTERNATIONAL}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
            styles={customSelectStyles}
            onChange={handleInternationalCodeSelect}
            placeholder="International Code"
            isSearchable={true}
          />
          <div className="input-box">
            <FormControl
              placeholder="Phone Number"
              value={form.phone}
              onChange={(e) => setForm({ ...form, phone: e.target.value })}
              style={{ flex: 1 }}
            />
          </div>

          {/* <label className="label-large-signup">Address</label>
          <div className="input-box">
            <FormControl
              placeholder="Address"
              value={form.option1}
              onChange={(e) => {
                setForm({ ...form, option1: e.target.value });
              }}
            />
          </div>
          <div className="input-box">
            <FormControl
              placeholder="Address 2 (optional)"
              value={form.option2}
              onChange={(e) => {
                setForm({ ...form, option2: e.target.value });
              }}
            />
          </div>
          <div className="input-box">
            <FormControl
              placeholder="State"
              value={form.state}
              onChange={(e) => {
                setForm({ ...form, state: e.target.value });
              }}
            />
            <FormControl
              placeholder="City"
              value={form.city}
              onChange={(e) => {
                setForm({ ...form, city: e.target.value });
              }}
            />
          </div>
          <div className="input-box" style={{ display: "flex", gap: "10px" }}>
            <FormControl
              placeholder="Zip Code"
              value={form.zip}
              onChange={(e) => {
                setForm({ ...form, zip: e.target.value });
              }}
              style={{ flex: 1 }}
            />
          </div>
          <label className="label-large-signup">Favorite Artist</label>
          <div className="input-box">
            <FormControl
              placeholder="Favorite artist"
              value={form.favorite}
              onChange={(e) => {
                setForm({ ...form, favorite: e.target.value });
              }}
            />
          </div> */}
          <p className="label-large-signup" style={{ marginBottom: "10px" }}>
            How did you learn about Ontact Korea ?
          </p>
          <Select
            options={options}
            value={options.find((option) => option.value === form.inflow)}
            onChange={(selectedOption) => {
              if (selectedOption) {
                setForm({ ...form, inflow: selectedOption.value });
              }
            }}
            placeholder="Inflow source"
          />
          <div style={{ margin: "1rem 0 2rem 0" }}>
            <p
              className="label-large-signup"
              style={{ marginBottom: "0.4rem" }}
            >
              Recommended Person
            </p>
            <div className="input-box">
              <FormControl
                placeholder="Space Code (ex. JK1234A)"
                onKeyPress={handleKeyPress}
                value={form.recommandedPerson.toUpperCase()}
                onChange={(e) => {
                  setForm({ ...form, recommandedPerson: e.target.value });
                }}
              />
            </div>
            <p>※ You can get a benefit!</p>
          </div>

          {/* <InputBox className="input-box">
            <FormCheck
              className="label-large-signup"
              id="old-user-check"
              label="Existing User"
              type="checkbox"
              checked={showOldIdInput}
              onChange={handleOldIdCheckboxChange}
            />
            {showOldIdInput && (
              <FormControl
                placeholder="Current space (starting with WH)"
                onKeyPress={handleKeyPress}
                value={form.oldid.toUpperCase()}
                onChange={(e) => setForm({ ...form, oldid: e.target.value.trim() })}
                style={{ flex: 1 }}
              />
            )}
          </InputBox>
          <div>
            ※ If you had an account on ontactkorea.kr please check the 'Existing
            user' option to keep your space code
          </div> */}

          <div className="mt-4">
            <Table>
              <tbody>
                <tr>
                  <th style={{ width: "40px" }}>
                    <FormCheck
                      id="input-1"
                      type="checkbox"
                      checked={agreements.agreeAll}
                      onChange={handleAgreeAllChange}
                    />
                  </th>
                  <td>
                    <label htmlFor="input-1">
                      I agree to all criteria below.
                    </label>
                  </td>
                </tr>
                <tr>
                  <th style={{ width: "40px" }}>
                    <FormCheck
                      id="input-2"
                      checked={agreements.agreeTerms}
                      onChange={handleIndividualAgreementChange("agreeTerms")}
                    />
                  </th>
                  <td>
                    <label
                      htmlFor="input-2"
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "0.4rem",
                        alignItems: "center",
                      }}
                    >
                      I agree to the
                      <a
                        target="_blank"
                        rel="noreferrer noopener"
                        href="https://docs.google.com/document/d/1SuZi6RC7hWQG9aXqoRLTO2HbZuH0wJ6uondJEFs5Z6s/edit"
                        className="text-link"
                        style={{ display: "inline-block" }}
                      >
                        Terms of Use
                      </a>
                      and the
                      <a
                        target="_blank"
                        rel="noreferrer noopener"
                        className="text-link"
                        style={{ display: "inline-block" }}
                      >
                        Privacy Policy.
                      </a>
                      *
                    </label>
                  </td>
                </tr>
                <tr>
                  <th style={{ width: "40px" }}>
                    <FormCheck
                      id="input-3"
                      type="checkbox"
                      checked={agreements.agreeAge}
                      onChange={handleIndividualAgreementChange("agreeAge")}
                    />
                  </th>
                  <td>
                    <label htmlFor="input-3">Above 14 years old *</label>
                  </td>
                </tr>
                <tr>
                  <th style={{ width: "40px" }}>
                    <FormCheck
                      id="input-4"
                      type="checkbox"
                      checked={form.agreeMarketing}
                      onChange={handleIndividualAgreementChange(
                        "agreeMarketing"
                      )}
                    />
                  </th>
                  <td>
                    <label htmlFor="input-4">
                      I agree to receive marketing communications.
                    </label>
                    <span
                      style={{
                        fontSize: "14px",
                        color: "gray",
                        display: "block",
                        marginTop: "0.6rem",
                      }}
                    >
                      You can opt-out at any time at My space
                    </span>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>

          <RegisterButton
            onClick={async (e) => {
              if (loading) return;
              setLoading(true);

              try {
                const isFormOk = verifyForm();
                if (!isFormOk.sign) {
                  Toast.fire({
                    icon: "warning",
                    title: isFormOk.message,
                  });
                  setLoading(false);
                  return;
                }

                if (form.recommandedPerson !== "") {
                  const isExistSpaceCode = await checkExistSpaceCode(
                    form.recommandedPerson
                  );
                  if (!isExistSpaceCode) {
                    Toast.fire({
                      icon: "warning",
                      title: `Space Code of a non-existent recommanded person.`,
                    });
                    setLoading(false);
                    return;
                  }
                }

                const res = await reqUserRegister(form);
                const myUserId = res.data.userId;

                await 회원가입_직전_자동지급_쿠폰이_있다면_지급(
                  myUserId,
                  form.recommandedPerson
                );

                if (form.Check) {
                  const badgePayload = {
                    badgeName: ADMIN_BADGE.SPACE,
                  };
                  await reqAddAdminBadge(badgePayload);
                }

                dispatch(
                  modalOpen({
                    modalType: MODAL_TYPE.INFO,
                    title: "Sign up success",
                    payload: {
                      text: "Thank you for Sign up.<br/>You can now login.<br/>Enjoy the Ontact Korea!",
                    },
                  })
                );
                nav("/login");
              } catch (error) {
                dispatch(
                  modalOpen({
                    modalType: MODAL_TYPE.INFO,
                    title: "Registration Failed",
                    payload: {
                      text: "Your account could not be registered. Please try again. Please confirm the email you are using or your password.",
                    },
                  })
                );
              } finally {
                setLoading(false);
              }
            }}
            style={{
              width: "100%",
            }}
            disabled={!agreements.agreeTerms || !agreements.agreeAge || loading}
          >
            Sign Up
          </RegisterButton>
        </Inner>
      </SignUpBox>
    </>
  );
}

const SignUpBox = styled.div`
  display: flex;
  justify-content: center;
  width: 90%;
  max-width: 600px;
  margin: 5rem auto;
  z-index: 1;
`;

const Inner = styled.div`
  margin: 40px;
  padding: 30px;
  box-shadow: 0px 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border-radius: calc(var(--bs-border-radius) - var(--bs-border-width));

  input[type="text"] {
    width: 100%;
  }

  > label {
    margin-top: 20px;
    margin-bottom: 7px;
  }
  .input-box {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
    align-items: center;
  }

  th {
    vertical-align: top;
  }
  td {
    span {
      color: var(--color-main-blue);
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;
const DefatulButton = styled.button`
  border: none;
  box-shadow: none;
  border-radius: 0;
  padding: 0.6em;
  border-radius: 0.4rem;
  overflow: visible;
  cursor: pointer;
`;
const ConfirmButton = styled(DefatulButton)`
  background-color: var(--color-main-blue);
  color: var(--color-white);
  &:enabled {
    background-color: var(--color-main-blue-hover);
    &:hover {
      background-color: var(--color-main-blue);
    }
  }
  &:hover {
    background-color: var(--color-main-blue-hover);
  }
`;
const RegisterButton = styled(DefatulButton)`
  background-color: var(--color-main-blue);
  color: var(--color-white);
  &:hover {
    background-color: var(--color-main-blue-hover);
  }
  &:disabled {
    background-color: gray;
    color: lightgray;
    cursor: not-allowed;
  }
`;

const SendCodeButton = styled(DefatulButton)`
  border: 2px solid var(--color-main-blue);
  background-color: #fff;
  &:hover {
    background-color: var(--color-main-blue);
    color: #fff;
  }
`;

const InputBox = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 800px) {
    flex-direction: row;
    align-items: center;
  }
`;
