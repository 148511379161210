export const DATE_FORMAT = {
  DEFAULT: "yyyy-MM-dd  HH:mm:ss",
  INPUT_STRING: "yyyy-MM-ddTHH:mm",
  ONLY_DATE: "yyyy-MM-dd",
};

// 로컬 스토리지에서 엑세스 토큰을 가져오는 함수
export const getAccessTokenFromLocalStorage = () => {
  const state = localStorage.getItem("state");
  if (state) {
    const parsedState = JSON.parse(state);
    return parsedState?.admin?.accessToken || null;
  }
  return null;
};

export const DELIVERY_COMPANIES = {
  ALL: 'ALL',
  FEDEX: 'FEDEX',
  UPS: 'UPS',
  EMS: 'EMS',
  PACKET: 'PACKET',
  CJ: 'CJ',
};

export const DATA_TYPE = {
  DATETIME: 1,
  DATE: 2,

  CODE_SHIPPING_PRODUCT_STATUS: 20,
  CODE_SHIPPING_ORDER_STATUS: 21,
  CODE_SHOPS_STATUS: 22,
  CODE_SHIPPING_ORDERS_STATUS: 23,
  AUTH_ROLE: 9991,
};

export const CODE_SHIPPING_ORDER_STATUS = {
  NONE: -1,
  DEFAULT: 0,
  ORDER_CREATED: 1,
  WAIT_PAY: 2,
  PAID: 3,
  PRODUCT_READY: 4,
  PRODUCT_SENT: 5,
  PRODUCT_CHECK: 6,
  CANCELED: 10,
  PRODUCT_SHIPPING: 99,
};

export const CODE_SHOPS_STATUS = {
  NONE: -1,
  DEFAULT: 0,
  REQUEST_SENT: 1,
  PENDING_PAYMENT: 2,
  CONFIRMING_DEPOSIT: 3,
  PENDING_COMPLETED: 4,
  ORDER_COMPLETED: 5,
  INCONMINT_COMPLETED: 6,
  CANCELED: 10,
  PROCESSING: 100,
};

export const CODE_SHIPPING_ORDERS_STATUS = {
  NONE: -1,
  DEFAULT: 0,
  REQUEST_SENT: 1,
  PACKING: 2,
  FEE_CALCULATION: 3,
  PENDING_PAYMENT: 4,
  CONFIRMING_DEPOSIT: 5,
  PREPARING_FOR_SHIPMENT: 6,
  SHIPPED: 7,
  DELIVERY_COMPLETED: 8,
  REVIEW_COMPLETED: 9,
  CANCELED: 10,
  DISCARDED: 11,
  SHIPPEDE: 99,
  PROCESSING: 100,
};

export const CODE_QNA = {
  NONE: -1,
  DEFAULT: 0,
  "주문 관련 문의": 1,
  "발송 관련 문의": 2,
  "창고 관련 문의": 3,
  "대량 주문 문의": 4,
  "결제 문의": 5,
  "기술 문의": 6,
  기타: 7,

  CANCELED: 10,
};

export const CODE_SHOPING_ORDER_STATUS = {
  NONE: -1,
  DEFAULT: 0,
  ORDER_CREATED: 1,
  WAIT_PAY: 2,
  PAID: 3,
  PRODUCT_PART: 4,
  PRODUCT_COMPLETION: 5,
  PRODUCT_WAREHOUSING: 6,
  NEW2: 7,
  EXPIRATION: 8,
  REAPPLIED: 9,
  CANCELED: 10,
  NEW: 11,
  TRANSACTION_OF_1_BETWEEN_2: 100,
};

export const ACODE_SHOPING_ORDER_STATUS = {
  NONE: -1,
  DEFAULT: 0,
  ORDER_CREATED: 1,
  Packed: 2,
  WAIT_PAY: 3,
  PAID: 4,
  PRODUCT_PART: 5,
  PRODUCT_COMPLETION: 6,
  PRODUCT_SHOPING: 99,
  PRODUCT_WAREHOUSING: 7,
  REVIEW: 8,
  CANCELED: 10,
  DISUSE: 9,
  EXPIRED: 11,
};
export const CODE_SHOPING_PRODUCT_STATUS = {
  NONE: -1,
  DEFAULT: 0,
  ORDER_CREATED: 1,
  WAIT_PAY: 2,
  PAID: 3,
  PRODUCT_PART: 4,
  PRODUCT_COMPLETION: 5,
  PRODUCT_WAREHOUSING: 6,
  PRODUCT_WAREHOUSIN: 7,
  PRODUCT_WAREHOUSI: 8,
  PRODUCT_WAREHOUS: 9,
  CANCELED: 10,
};

export const CODE_SHOPING_PRODUCT_DETAIL_STATUS = {
  NONE: -1,
  DEFAULT: 0,
  ORDER_CREATED: 1,
  POSSIBILITY: 2,
  EXPIRATION: 3,
  RESTRICTION: 4,
  SOLD_OUT: 5,
  WAREHOUSING: 6,
  POINT: 9,
  CANCELED: 10,
};

export const INFLOW_SOURCES = {
  GOOGLE: 0,
  INSTAGRAM: 1,
  TIKTOK: 2,
  WATCHA: 3,
  KAKAOTALK: 4,
  SEARCH: 5,
  LINE: 6,
  OTHER: 7,
};

export const CODE_SHIPPING_PRODUCT_STATUS = {
  NONE: -1,
  DEFAULT: 0,
  ENTER_DATA: 1,
  SHIPPING_AVAILABLE: 2,
  ORDER_SENT: 3,
  CHECK_CONTENT: 4,
  SPLIT_ITEMS: 5,
  SHIPPED: 6,
  CANCELED: 10,
  EXPIRED: 11,
  DISCARDED: 20,
};

export const CODE_SHIPPING_PRODUCT_STATUS_OPTIONS: { [key: number]: string } = {
  1: "Enter data",
  2: "Ready to ship",
  3: "Packing",
  4: "Checked items",
  5: "Split items",
  6: "Shipped",
  // 10: "Canceled",
  // 11: "Expired",
  20: "Discarded",
};

export const MEMBERSHIP: { [key: number]: string } = {
  [-1]: "None",
  [0]: "Default",
  [1]: "Red",
  [2]: "Blue",
  [3]: "Green",
};

export const ROUTE_URL = {
  INDEX: "",
  LOGIN: "/login",
};

export const CODE_SHIPPING_PAYMENT_STATUS: { [key: number]: string } = {
  [-1]: "None",
  0: "Default",
  1: "통장",
  2: "페이팔",
  3: "신용카드",
  10: "Canceled",
};

export const CODE_SHIPPING_COMPANY: { [key: number]: string } = {
  [-1]: "None",
  0: "정보없음",
  1: "FEDEX",
  2: "UPS",
  3: "EMS",
  4: "PACKET",
  5: "CJ",
};

export const CODE_SHIPPING_PAY: { [key: number]: string } = {
  [-1]: "None",
  0: "정보없음",
  1: "무통장",
  2: "페이팔",
  3: "기타",
};

export const CODE_SHOPING_PAYMENT_STATUS: { [key: number]: string } = {
  [-1]: "None",
  0: "Default",
  1: "Request Sent",
  2: "Packing",
  3: "Fee calculation",
  4: "Pending payment",
  5: "Confirming deposit",
  6: "Preparing for shipment",
  7: "Shipped",
  8: "Delivery completed",
  9: "Review completed",
  10: "Cancel",
  11: "Discarded",
  99: "Shipped",
  100: "Processing",
};

export const CODE_SHIPING_STATUS: { [key: number]: string } = {
  1: "요청서",
  2: "포장 중",
  3: "결제 견적서",
  4: "결제 대기중",
  5: "결제 완료",
  6: "특송사 배송 대기중",
  7: "특송사 배송",
  11: "폐기",
};

export const CODE_SHOPING_PAYMENT_STATUS_OPTIONS: { [key: number]: string } = {
  1: "Request Sent",
  2: "Packing",
  4: "Pending payment",
  5: "Confirming deposit",
  6: "Preparing for shipment",
  7: "Shipped",
  8: "Delivery completed",
  // 9: "Review completed",
  11: "Discarded",
};

export const CODE_SHOP_STATUS: { [key: number]: string } = {
  1: "Request Sent",
  2: "Pending payment",
  3: "Confirming deposit",
  4: "Payment completed",
  6: "Order completed",
  10: "Cancelled",
  100: "Processing",
};

export const CODE_SHOP_STATUS_OPTIONS: { [key: number]: string } = {
  1: "Request Sent",
  2: "Pending payment",
  3: "Confirming deposit",
  4: "Payment completed",
  6: "Order completed",
  10: "Cancelled",
  100: "Processing",
};

export const CODE_QNA_STATUS: { [key: number]: string } = {
  1: "CUSTOM_ORDER_SERVICE",
  2: "SHIPMENT_SERVICE",
  3: "WAREHOUSE_SERVICE",
  4: "BULK_ORDER",
  5: "PAYMENT",
  6: "TECHNICAL_ISSUE",
  7: "OTHERS",
};

export const CODE_QNA_K: { [key: number]: string } = {
  1: "주문 관련 문의",
  2: "발송 관련 문의",
  3: "창고 관련 문의",
  4: "대량 주문 문의",
  5: "결제 문의",
  6: "기술 문의",
  7: "기타",
};

export const CODE_BY_STATUS: { [key: number]: string } = {
  1: "주문 대기",
  2: "구매 가능",
  3: "구매기한 만료",
  4: "구매수량 제한",
  5: "품절",
  6: "입고등록 대기",
};

export const CODE_SHOPPING_ORDER_STATUS: { [key: number]: string } = {
  1: "요청서",
  2: "결제 대기중",
  3: "결제 완료",
  6: "전체 주문 완료",
  10: "주문 취소",
  99: "관리자 확인 중",
};

export const CODE_SHOPPING_ORDER_ITEMS: { [key: number]: string } = {
  1: "Accessories (Earrings, Ring, etc.)",
  2: "Acrylic Stand",
  3: "Air Cleaner/Air Purifier",
  4: "Albums",
  5: "Bag",
  6: "Balls",
  7: "Battery Charger",
  8: "Bed, Mattress",
  9: "Belt",
  10: "Beverage",
  11: "Blanket",
  12: "Blu-ray",
  13: "Bluetooth Device",
  14: "Board Game",
  15: "Bodycare (Lotion, Soap, Shampoo)",
  16: "Book, Comics",
  17: "Brush",
  18: "Cable",
  19: "Candle",
  20: "Candy",
  21: "Car Parts",
  22: "Case",
  23: "Cassette",
  24: "CD",
  25: "Chair",
  26: "Clock",
  27: "Clothes",
  28: "Clothing Accessories",
  29: "Color Cosmetics",
  30: "Contact Lens",
  31: "Contact Lens Cleaner",
  32: "Cup",
  33: "Cup Sleeves",
  34: "Cutlery Set",
  35: "Cutting Board",
  36: "Denim",
  37: "Dental Floss",
  38: "Detergent, Washing Preparations",
  39: "Digital Camera",
  40: "Dish",
  41: "Dolls",
  42: "Doorbells, Chimes, Buzzers",
  43: "Dress",
  44: "DVD",
  45: "Electrical Beauty Appliances",
  46: "Electronic Sound Set",
  47: "Electronics Rice Cooker",
  48: "Eyeshadow",
  49: "Fabric",
  50: "Fan",
  51: "Fashion Mask",
  52: "Figure",
  53: "Flower Pots",
  54: "Footwear (Dress Shoes)",
  55: "Footwear (Gym Shoes, Sandals)",
  56: "Frame for Painting, Photographs",
  57: "Game Box",
  58: "Glasses/Sunglass",
  59: "Hair Brush",
  60: "Hair Dye",
  61: "Hair-band/Hair-pin",
  62: "Hard Drive/SSD/HDD",
  63: "Hat, Cap",
  64: "Headphones",
  65: "Heat Pack",
  66: "Humidifier",
  67: "Image Picket",
  68: "Instant Coffee/Coffee Mix",
  69: "Instant Food",
  70: "Jackets, Blazers, and Jumpers",
  71: "Jewellery (Bracelet, Necklace, etc)",
  72: "Keyboard",
  73: "Keyboard/Mouse",
  74: "Keyring",
  75: "Kitchen Supplies",
  76: "Laptop PC",
  77: "Lavatory Seats and Covers",
  78: "Lighting Equipment",
  79: "Lighting Instruments",
  80: "Lightstick",
  81: "Lightstick (Battery)",
  82: "Makeup Brush",
  83: "Mask for Hygienic Purpose",
  84: "Mechanic Parts",
  85: "Medical Mask",
  86: "Merch Box",
  87: "Mobile Phone",
  88: "Monitor",
  89: "Music Box",
  90: "Nail/Manicure/Pedicure",
  91: "Note",
  92: "Notebook",
  93: "Nutritional Supplement",
  98: "Pants",
  99: "Pants and Trousers",
  100: "Pen",
  101: "Perfume",
  102: "Pet Food, Snack",
  103: "Pet Products",
  104: "Photo Album",
  105: "Photo Book",
  106: "Photo Card",
  107: "Pillow, Cushion",
  108: "Portable Game Device",
  109: "Poster",
  110: "Pot, Saucepan",
  111: "Pouch, Wallets",
  112: "Powder",
  113: "Printer",
  114: "Ramen, Noodle",
  115: "Sanitary Pads for Women",
  116: "Scarfs/Muffler",
  117: "Selfie Stick",
  118: "Sheet Mask",
  119: "Shoes",
  120: "Skin Care Products",
  121: "Skirts",
  122: "Smart Watch",
  123: "Snack",
  124: "Snow Ball/Christmas Festival Items",
  125: "Socks, Stocking, Leggings",
  126: "Sports Equipment",
  127: "Stationery",
  128: "Sticker",
  129: "Sticker Set",
  130: "Swimsuit",
  131: "Table",
  132: "Table Mats",
  133: "Tablet PC",
  134: "Tapestry, Rug",
  135: "Tea",
  136: "Tent",
  137: "Toolbox",
  138: "Toothbrushes",
  139: "Towels",
  140: "Toys",
  141: "T-shirts, Shirts, Hoodie",
  142: "Umbrella",
  143: "Underwear",
  144: "Wallet",
  145: "Watch",
  146: "Magazine",
  147: " Bathroom Supplies",
};

export const CODE_COUNTRY = [
  { code: "AL", name: "Albania" },
  { code: "DZ", name: "Algeria" },
  { code: "AS", name: "American Samoa" },
  { code: "AD", name: "Andorra" },
  { code: "AO", name: "Angola" },
  { code: "AI", name: "Anguilla" },
  { code: "AG", name: "Antigua" },
  { code: "AR", name: "Argentina" },
  { code: "AM", name: "Armenia" },
  { code: "AW", name: "Aruba" },
  { code: "AU", name: "Australia" },
  { code: "AT", name: "Austria" },
  { code: "AZ", name: "Azerbaijan" },
  { code: "BS", name: "Bahamas" },
  { code: "BH", name: "Bahrain" },
  { code: "BD", name: "Bangladesh" },
  { code: "BB", name: "Barbados" },
  { code: "BY", name: "Belarus" },
  { code: "BE", name: "Belgium" },
  { code: "BZ", name: "Belize" },
  { code: "BJ", name: "Benin" },
  { code: "BM", name: "Bermuda" },
  { code: "BT", name: "Bhutan" },
  { code: "BO", name: "Bolivia" },
  { code: "BW", name: "Botswana" },
  { code: "BR", name: "Brazil" },
  { code: "VG", name: "British Virgin Is" },
  { code: "BN", name: "Brunei" },
  { code: "BG", name: "Bulgaria" },
  { code: "BF", name: "Burkino Faso" },
  { code: "MM", name: "Burma" },
  { code: "BI", name: "Burundi" },
  { code: "KH", name: "Cambodia" },
  { code: "CM", name: "Cameroon" },
  { code: "CA", name: "Canada" },
  { code: "CV", name: "Cape Verde" },
  { code: "KY", name: "Cayman Islands" },
  { code: "CF", name: "Central African" },
  { code: "TD", name: "Chad" },
  { code: "CL", name: "Chile" },
  { code: "CN", name: "China" },
  { code: "CO", name: "Colombia" },
  { code: "CG", name: "Congo" },
  { code: "CD", name: "Congo, The Republic" },
  { code: "CK", name: "Cook Islands" },
  { code: "CR", name: "Costa Rica" },
  { code: "CI", name: "Cote D'Ivoire" },
  { code: "HR", name: "Croatia" },
  { code: "CY", name: "Cyprus" },
  { code: "CZ", name: "Czech Republic" },
  { code: "DK", name: "Denmark" },
  { code: "DJ", name: "Djibouti" },
  { code: "DM", name: "Dominica" },
  { code: "DO", name: "Dominican Republic" },
  { code: "EC", name: "Ecuador" },
  { code: "EG", name: "Egypt" },
  { code: "SV", name: "El Salvador" },
  { code: "GQ", name: "Equatorial Guinea" },
  { code: "ER", name: "Eritrea" },
  { code: "EE", name: "Estonia" },
  { code: "ET", name: "Ethiopia" },
  { code: "FO", name: "Faeroe Islands" },
  { code: "FJ", name: "Fiji" },
  { code: "FI", name: "Finland" },
  { code: "FR", name: "France" },
  { code: "GF", name: "French Guiana" },
  { code: "PF", name: "French Polynesia" },
  { code: "GA", name: "Gabon" },
  { code: "GM", name: "Gambia" },
  { code: "GE", name: "Georgia, Republic of" },
  { code: "DE", name: "Germany" },
  { code: "GH", name: "Ghana" },
  { code: "GI", name: "Gibraltar" },
  { code: "GR", name: "Greece" },
  { code: "GL", name: "Greenland" },
  { code: "GD", name: "Grenada" },
  { code: "GP", name: "Guadeloupe" },
  { code: "GU", name: "Guam" },
  { code: "GT", name: "Guatemala" },
  { code: "GN", name: "Guinea" },
  { code: "GW", name: "Guinea-Bissau" },
  { code: "GY", name: "Guyana" },
  { code: "HT", name: "Haiti" },
  { code: "HN", name: "Honduras" },
  { code: "HK", name: "Hong Kong" },
  { code: "HU", name: "Hungary" },
  { code: "IS", name: "Iceland" },
  { code: "IN", name: "India" },
  { code: "ID", name: "Indonesia" },
  { code: "IE", name: "Ireland" },
  { code: "IL", name: "Israel" },
  { code: "IT", name: "Italy" },
  { code: "CI", name: "Ivory Coast" },
  { code: "JM", name: "Jamaica" },
  { code: "JP", name: "Japan" },
  { code: "JO", name: "Jordan" },
  { code: "KZ", name: "Kazakhstan" },
  { code: "KE", name: "Kenya" },
  { code: "KW", name: "Kuwait" },
  { code: "KG", name: "Kyrgyzstan" },
  { code: "LV", name: "Latvia" },
  { code: "LB", name: "Lebanon" },
  { code: "LS", name: "Lesotho" },
  { code: "LI", name: "Liechtenstein" },
  { code: "LT", name: "Lithuania" },
  { code: "LU", name: "Luxembourg" },
  { code: "MO", name: "Macau" },
  { code: "MK", name: "Macedonia" },
  { code: "MG", name: "Madagascar" },
  { code: "MW", name: "Malawi" },
  { code: "MY", name: "Malaysia" },
  { code: "MV", name: "Maldives" },
  { code: "ML", name: "Mali" },
  { code: "MT", name: "Malta" },
  { code: "MH", name: "Marshall Islands" },
  { code: "MQ", name: "Martinique" },
  { code: "MR", name: "Mauritania" },
  { code: "MU", name: "Mauritius" },
  { code: "MX", name: "Mexico" },
  { code: "FM", name: "Micronesia" },
  { code: "MD", name: "Moldova" },
  { code: "MC", name: "Monaco" },
  { code: "MN", name: "Mongolia" },
  { code: "MS", name: "Montserrat" },
  { code: "MA", name: "Morocco" },
  { code: "MZ", name: "Mozambique" },
  { code: "NA", name: "Namibia" },
  { code: "MM", name: "Myanmar" },
  { code: "NP", name: "Nepal" },
  { code: "NL", name: "Netherlands" },
  { code: "AN", name: "Netherlands Antilles" },
  { code: "NC", name: "New Caledonia" },
  { code: "NZ", name: "New Zealand" },
  { code: "NI", name: "Nicaragua" },
  { code: "NE", name: "Niger" },
  { code: "NG", name: "Nigeria" },
  { code: "NO", name: "Norway" },
  { code: "OM", name: "Oman" },
  { code: "PK", name: "Pakistan" },
  { code: "PW", name: "Palau" },
  { code: "PA", name: "Panama" },
  { code: "PG", name: "Papua New Guinea" },
  { code: "PY", name: "Paraguay" },
  { code: "PE", name: "Peru" },
  { code: "PH", name: "Philippines" },
  { code: "PL", name: "Poland" },
  { code: "PT", name: "Portugal" },
  { code: "PR", name: "Puerto Rico" },
  { code: "QA", name: "Qatar" },
  { code: "RE", name: "Reunion Island" },
  { code: "RO", name: "Romania" },
  { code: "RU", name: "Russia" },
  { code: "RW", name: "Rwanda" },
  { code: "MP", name: "Saipan" },
  { code: "SM", name: "San Marino" },
  { code: "SA", name: "Saudi Arabia" },
  { code: "SN", name: "Senegal" },
  { code: "SC", name: "Seychelles" },
  { code: "SL", name: "Sierra Leone" },
  { code: "SG", name: "Singapore" },
  { code: "SK", name: "Slovak Republic" },
  { code: "SI", name: "Slovenia" },
  { code: "ZA", name: "South Africa" },
  { code: "KR", name: "South Korea" },
  { code: "ES", name: "Spain" },
  { code: "LK", name: "Sri Lanka" },
  { code: "KN", name: "St. Kitts & Nevis" },
  { code: "LC", name: "St. Lucia" },
  { code: "VC", name: "St. Vincent" },
  { code: "SR", name: "Suriname" },
  { code: "SZ", name: "Swaziland" },
  { code: "SE", name: "Sweden" },
  { code: "CH", name: "Switzerland" },
  { code: "SY", name: "Syria" },
  { code: "TW", name: "Taiwan" },
  { code: "TZ", name: "Tanzania" },
  { code: "TH", name: "Thailand" },
  { code: "TG", name: "Togo" },
  { code: "TT", name: "Trinidad & Tobago" },
  { code: "TN", name: "Tunisia" },
  { code: "TR", name: "Turkey" },
  { code: "TM", name: "Turkmenistan, Republic of" },
  { code: "TC", name: "Turks & Caicos Islands" },
  { code: "AE", name: "United Arab Emirates" },
  { code: "VI", name: "U.S. Virgin Islands" },
  { code: "US", name: "United States" },
  { code: "UG", name: "Uganda" },
  { code: "UA", name: "Ukraine" },
  { code: "GB", name: "United Kingdom" },
  { code: "UY", name: "Uruguay" },
  { code: "UZ", name: "Uzbekistan" },
  { code: "VU", name: "Vanuatu" },
  { code: "VA", name: "Vatican City" },
  { code: "VE", name: "Venezuela" },
  { code: "VN", name: "Vietnam" },
  { code: "WF", name: "Wallis & Futuna Islands" },
  { code: "YE", name: "Yemen" },
  { code: "ZM", name: "Zambia" },
  { code: "ZW", name: "Zimbabwe" },
  { code: "RS", name: "Serbia" },
];

export const CODE_COUNTRY_ITEMS: { [key: number]: string | string[] } = {
  1: "Albania",
  2: "Algeria",
  3: "American Samoa",
  4: "Andorra",
  5: "Angola",
  6: "Anguilla",
  7: "Antigua",
  8: "Argentina",
  9: "Armenia",
  10: "Aruba",
  11: "Australia",
  12: "Austria",
  13: "Azerbaijan",
  14: "Bahamas",
  15: "Bahrain",
  16: "Bangladesh",
  17: "Barbados",
  18: "Belarus",
  19: "Belgium",
  20: "Belize",
  21: "Benin",
  22: "Bermuda",
  23: "Bhutan",
  24: "Bolivia",
  25: "Botswana",
  26: "Brazil",
  27: "British Virgin Is",
  28: "Brunei",
  29: "Bulgaria",
  30: "Burkina Faso",
  31: "Burma",
  32: "Burundi",
  33: "Cambodia",
  34: "Cameroon",
  35: "Canada",
  36: "Cape Verde",
  37: "Cayman Islands",
  38: "Central African",
  39: "Chad",
  40: "Chile",
  41: "China",
  42: "Colombia",
  43: "Congo",
  44: "Congo, The Republic",
  45: "Cook Islands",
  46: "Costa Rica",
  47: "Cote D'Ivoire",
  48: "Croatia",
  49: "Cyprus",
  50: "Czech Republic",
  51: "Denmark",
  52: "Djibouti",
  53: "Dominica",
  54: "Dominican Republic",
  55: "Ecuador",
  56: "Egypt",
  57: "El Salvador",
  58: "Equatorial Guinea",
  59: "Eritrea",
  60: "Estonia",
  61: "Ethiopia",
  62: "Faeroe Islands",
  63: "Fiji",
  64: "Finland",
  65: "France",
  66: "French Guiana",
  67: "French Polynesia",
  68: "Gabon",
  69: "Gambia",
  70: "Georgia, Republic of",
  71: "Germany",
  72: "Ghana",
  73: "Gibraltar",
  74: "Greece",
  75: "Greenland",
  76: "Grenada",
  77: "Guadeloupe",
  78: "Guam",
  79: "Guatemala",
  80: "Guinea",
  81: "Guinea-Bissau",
  82: "Guyana",
  83: "Haiti",
  84: "Honduras",
  85: "Hong Kong",
  86: "Hungary",
  87: "Iceland",
  88: "India",
  89: "Indonesia",
  90: "Ireland",
  91: "Israel",
  92: "Italy",
  93: "Ivory Coast",
  94: "Jamaica",
  95: "Japan",
  96: "Jordan",
  97: "Kazakhstan",
  98: "Kenya",
  99: "Kuwait",
  100: "Kyrgyzstan",
  101: "Latvia",
  102: "Lebanon",
  103: "Lesotho",
  104: "Liechtenstein",
  105: "Lithuania",
  106: "Luxembourg",
  107: "Macau",
  108: "Macedonia",
  109: "Madagascar",
  110: "Malawi",
  111: "Malaysia",
  112: "Maldives",
  113: "Mali",
  114: "Malta",
  115: "Marshall Islands",
  116: "Martinique",
  117: "Mauritania",
  118: "Mauritius",
  119: "Mexico",
  120: "Micronesia",
  121: "Moldova",
  122: "Monaco",
  123: "Mongolia",
  124: "Montserrat",
  125: "Morocco",
  126: "Mozambique",
  127: "Myanmar",
  128: "Namibia",
  129: "Nepal",
  130: "Netherlands",
  131: "Netherlands Antilles",
  132: "New Caledonia",
  133: "New Zealand",
  134: "Nicaragua",
  135: "Niger",
  136: "Nigeria",
  137: "Norway",
  138: "Oman",
  139: "Pakistan",
  140: "Palau",
  141: "Panama",
  142: "Papua New Guinea",
  143: "Paraguay",
  144: "Peru",
  145: "Philippines",
  146: "Poland",
  147: "Portugal",
  148: "Puerto Rico",
  149: "Qatar",
  150: "Reunion Island",
  151: "Romania",
  152: "Russia",
  153: "Rwanda",
  154: "Saipan",
  155: "San Marino",
  156: "Saudi Arabia",
  157: "Senegal",
  158: "Seychelles",
  159: "Sierra Leone",
  160: "Singapore",
  161: "Slovak Republic",
  162: "Slovenia",
  163: "South Africa",
  164: "South Korea",
  165: "Spain",
  166: "Sri Lanka",
  167: "St. Kitts & Nevis",
  168: "St. Lucia",
  169: "St. Vincent",
  170: "Suriname",
  171: "Swaziland",
  172: "Sweden",
  173: "Switzerland",
  174: "Syria",
  175: "Taiwan",
  176: "Tanzania",
  177: "Thailand",
  178: "Togo",
  179: "Trinidad & Tobago",
  180: "Tunisia",
  181: "Turkey",
  182: "Turkmenistan, Republic of",
  183: "Turks & Caicos Is.",
  184: "United Arab Emirates",
  185: "U.S. Virgin Islands",
  186: "United States",
  187: "Uganda",
  188: "Ukraine",
  189: "United Kingdom",
  190: "Uruguay",
  191: "Uzbekistan",
  192: "Vanuatu",
  193: "Vatican City",
  194: "Venezuela",
  195: "Vietnam",
  196: "Wallis & Futuna Islands",
  197: "Yemen",
  198: "Zambia",
  199: "Zimbabwe",
  200: "Serbia",
};

export const RECOMMANDATION_SHOP_LIST_K_POP = {
  "https://www.sound-wave.co.kr/": "1.png",
  "https://www.everlineshop.com/": "2.svg",
  "https://www.musickorea.asia/": "3.png",
  "https://www.withmuu.com/": "4.svg",
  "https://www.musicplant.co.kr/": "5.jpg",
  "https://www.makestar.co/": "6.svg",
  "https://www.applemusic.co.kr/": "7.png",
  "https://thebdm.co.kr/": "8.png",
  "https://kr.ktown4u.com/": "9.png",
  "https://www.aladin.co.kr/home/welcome.aspx": "10.jpg",
  "https://www.yes24.com/Main/default.aspx": "11.svg",
  "https://whosfanstore.com/": "12.svg",
  "https://weverseshop.io/ko/home": "13.svg",
  "https://thejypshop.com/": "14-1.jpg",
  "https://smtownandstore.com/": "15.png",
  "https://ygselect.com/": "16.svg",
  "https://kqshop.kr/": "17.png",
  "https://www.madeedam.com/main/index.php": "18.jpg",
  "https://www.starship-square.com/": "19.png",
  "https://www.bizent.co.kr/": "20.png",
  "https://fanslike.io/": "21.png",
  "https://store.frommyarti.com/arti": "22.svg",
};

export const RECOMMANDATION_SHOP_LIST_BEAUTY = {
  "https://www.oliveyoung.co.kr": "23.png",
  "https://www.etude.com": "24.png",
  "https://www.amoremall.com": "25.svg",
  "https://www.banila.com": "26.png",
  "https://www.sulwhasoo.com": "27.png",
  "https://www.mediheal.com": "28.png",
  "https://www.innisfree.com": "29.png",
  "https://www.peachandlily.com": "30.png",
  "https://www.cosrx.co.kr": "31.png",
  "https://www.drjart.co.uk": "32.png",
  "https://www.belifmall.com": "33.png",
  "https://www.somebymi.co.kr": "34.png",
  "https://www.laneige.com": "35.svg",
  "https://www.imfrom.co.kr": "36.png",
  "https://www.glowrecipe.com": "37.svg",
  "https://www.clubclio.co.kr": "38.jpg",
};

export const RECOMMANDATION_SHOP_LIST_FASHION = {
  "https://mahagrid.com": "39.png",
  "https://studio-tailor.com": "40.jpg",
  "https://reinsein.co.kr/": "41.png",
  "https://spao.com/": "42.png",
  "https://rdvz.kr/": "43.png",
  "https://en.waikei.co.kr/": "44.jpg",
  "https://standoil.kr": "45.png",
  "https://adidas.co.kr": "46-1.webp",
  "https://abcmart.a-rt.com": "47.png",
  "https://satur.co.kr/": "48.png",
  "https://ames-worldwide.com/": "49.svg",
  "https://romanticcrown.com/": "50.png",
};

export const RECOMMANDATION_SHOP_LIST_MANHWA = {
  "https://smartstore.naver.com/official_w": "51.jpg",
  "https://mostore.co.kr/": "52.jpg",
  "https://webtoonfriends.shop/en": "53.png",
  "https://crackent.com/": "54.png",
  "https://webtoonshop.com/": "55.png",
  "https://smartstore.naver.com/beongoodsshop": "56.jpg",
  "https://www.animate-onlineshop.co.kr/": "57.jpg",
  "https://audiocomics.kr/audiodrama/main": "58.png",
  "https://marpple.shop/kr/studiozzu": "59.png",
  "https://j-meeshop.com/": "60.png",
  "https://toonique.co.kr/": "61.png",
};

export const RECOMMANDATION_SHOP_LIST_STATIONARY = {
  "https://linefriendssquare.com/": "62.png",
  "https://ssg.com": "63.png",
  "https://gmarket.co.kr": "64.png",
  "https://lucalab.com/": "65.png",
  "https://m.duckyworld.co.kr/": "66.png",
  "https://www.daisomall.co.kr/ds": "67.png",
};

export const RECOMMANDATION_SHOP_LIST_SNACKS = {
  "https://www.lotteon.com/p/display/main/lotteon?mall_no=1": "68.png",
  "https://coupang.com": "69.png",
};

export const USER_BADGE = {
  ALL_PRODUCT: "ALL_PRODUCT",
  MODIFY_PRODUCT: "MODIFY_PRODUCT",
  LOST_PRODUCT: "LOST_PRODUCT",
  CHECK_CONTENT: "CHECK_CONTENT",
  SPLIT: "SPLIT",
  SHOP_PAYMENT_WAITING: "SHOP_PAYMENT_WAITING",
  SHIP_PAYMENT_WAITING: "SHIP_PAYMENT_WAITING",
};

export const ADMIN_BADGE = {
  SHOP_REQUEST_FORM: "SHOP_REQUEST_FORM",
  SHOP_PAYMENT_WAITING: "SHOP_PAYMENT_WAITING",
  SHOP_REQUEST_CANCEL: "SHOP_REQUEST_CANCEL",
  SHIP_REQUEST_FORM: "SHIP_REQUEST_FORM",
  SHIP_PAYMENT_WAITING: "SHIP_PAYMENT_WAITING",
  CHECK_CONTENT: "CHECK_CONTENT",
  LOST_PRODUCT: "LOST_PRODUCT",
  FREE_EXPIRE: "FREE_EXPIRE",
  QNA: "QNA",
  REVIEW: "REVIEW",
  SPACE: "SPACE",
  SPLIT: "SPLIT",
};

export const MEMBERSHIP_GRADE = {
  1: "BLUE",
  2: "GREEN",
  3: "RED",
};

export const DISCOUNT_TYPE = {
  PERCENT: "PERCENT",
  FLAT: "FLAT",
};

export const CURRENCY_TYPE = {
  KRW: "KRW",
  USD: "USD",
};

export const COUPON_DUE_TYPE = {
  FIXED: "FIXED",
  DUE: "DUE",
};

export const USE_CONDITION = {
  RATING_FEE: "RATING_FEE",
  CHECK_CONTENTS: "CHECK_CONTENTS",
  SHOP_SERVICE_FEE: "SHOP_SERVICE_FEE",
  REPACK_FEE: "REPACK_FEE",
  MEMBERSHIP: "MEMBERSHIP",
  NONE: "-",
};

export const USE_CONDITION_KR = {
  RATING_FEE: "Shipping Fee",
  CHECK_CONTENTS: "Check Items",
  SHOP_SERVICE_FEE: "Custom order Service fee",
  REPACK_FEE: "Packaging fee",
  MEMBERSHIP: "Membership (Blue, Red)",
  NONE: "-",
};

export const AUTO_RECEIVE_CONDITION = {
  REGISTER: "REGISTER",
  ADD_RECOMMAND: "ADD_RECOMMAND",
  RECEIVE_RECOMMAND: "RECEIVE_RECOMMAND",
  LINK: "LINK",
  MEMBERSHIP: "MEMBERSHIP",
  NONE: "-",
};

export const AUTO_RECEIVE_CONDITION_KR = {
  REGISTER: "가입했을때",
  ADD_RECOMMAND: "추천인 작성시",
  RECEIVE_RECOMMAND: "추천인 받았을시",
  LINK: "특정 링크 접속해서 쿠폰 받기 눌렀을때",
  MEMBERSHIP: "멤서쉅 가입시",
  NONE: "-",
};

export const PAYMENT_METHOD = {
  PAYPAL: "PAYPAL",
  CREDIT_CARD: "CREDIT_CARD",
  EXPRESS: "EXPRESS",
};

export const INTERNATIONAL = [
  "1",
  "1340",
  "1670",
  "1671",
  "1684",
  "1787",
  "1441",
  "1242",
  "1246",
  "1264",
  "1268",
  "1284",
  "1345",
  "1473",
  "1649",
  "1664",
  "1758",
  "1767",
  "1784",
  "1809",
  "1829",
  "1868",
  "1869",
  "1876",
  "1939",
  "20",
  "211",
  "212",
  "213",
  "216",
  "218",
  "220",
  "221",
  "222",
  "223",
  "224",
  "225",
  "226",
  "227",
  "228",
  "229",
  "230",
  "231",
  "232",
  "233",
  "234",
  "235",
  "236",
  "237",
  "238",
  "239",
  "240",
  "241",
  "242",
  "243",
  "244",
  "245",
  "246",
  "247",
  "248",
  "249",
  "250",
  "251",
  "252",
  "253",
  "254",
  "255",
  "256",
  "257",
  "258",
  "260",
  "261",
  "262",
  "263",
  "264",
  "265",
  "266",
  "267",
  "268",
  "269",
  "27",
  "290",
  "291",
  "297",
  "298",
  "299",
  "30",
  "31",
  "32",
  "33",
  "34",
  "350",
  "351",
  "352",
  "353",
  "354",
  "355",
  "356",
  "357",
  "358",
  "359",
  "36",
  "370",
  "371",
  "372",
  "373",
  "374",
  "375",
  "376",
  "377",
  "378",
  "380",
  "381",
  "382",
  "383",
  "385",
  "386",
  "387",
  "389",
  "39",
  "40",
  "41",
  "420",
  "421",
  "423",
  "43",
  "44",
  "45",
  "46",
  "47",
  "48",
  "49",
  "500",
  "501",
  "502",
  "503",
  "504",
  "505",
  "506",
  "507",
  "508",
  "509",
  "51",
  "52",
  "53",
  "54",
  "55",
  "56",
  "57",
  "58",
  "590",
  "591",
  "592",
  "593",
  "594",
  "595",
  "596",
  "597",
  "598",
  "599",
  "60",
  "61",
  "62",
  "63",
  "64",
  "65",
  "66",
  "670",
  "672",
  "673",
  "674",
  "675",
  "676",
  "677",
  "678",
  "679",
  "680",
  "681",
  "682",
  "683",
  "685",
  "686",
  "687",
  "688",
  "689",
  "690",
  "691",
  "692",
  "7",
  "81",
  "82",
  "84",
  "850",
  "852",
  "853",
  "855",
  "856",
  "86",
  "880",
  "886",
  "90",
  "90392",
  "91",
  "92",
  "93",
  "94",
  "95",
  "960",
  "961",
  "962",
  "963",
  "964",
  "965",
  "966",
  "967",
  "968",
  "970",
  "971",
  "972",
  "973",
  "974",
  "975",
  "976",
  "977",
  "98",
  "992",
  "993",
  "994",
  "995",
  "996",
  "998",
].map((code) => ({ value: code, label: `+${code}` }));
