import JsBarcode from "jsbarcode";
import React, { useEffect, useRef, useState } from "react";
import { Accordion, Button, Collapse, Form, Modal } from "react-bootstrap";
import styled from "styled-components";
import Swal from "sweetalert2";
import { CODE_COUNTRY_ITEMS } from "../../common/constants";
import {
  reqPutExpress,
  reqShip,
  reqShipOrderUpdate,
  reqShipProductBox,
  reqShipProductDetailUpdate,
  reqShipUpdateOrder,
  reqUploadShipOrderImage,
} from "../../requests/ship";

interface RepackModalProps {
  show: boolean;
  onHide: () => void;
  orderNumber: string;
  refreshData: () => void;
}

interface Product {
  checkEmail: string;
  checkPrice: number;
  checkPayment: number;
  check: boolean;
  tracking: string;
  isUrgent: boolean;
  bag: boolean;
  boxed: boolean;
}

interface Item {
  id: number;
  trackingNumber: string[];
  weightKg: number;
  heightCm: number;
  widthCm: number;
  lengthCm: number;
  suspectBattery: boolean;
  food: boolean;
  volumetricWeightFedexUps?: number;
  volumetricWeightEmsKPacket?: number;
  file?: File;
  imageUrl?: string;
  imagePreview?: string;
}

interface OrderDetails {
  products: Product[];
  optionBox: number;
  optionLetter: boolean;
  optionPoster: boolean;
  optionAircap: boolean;
  optionBag: boolean;
  optionSticker: boolean;
  weightKg: number;
  adrCountry: number;
  heightCm: number;
  widthCm: number;
  lengthCm: number;
  suspectBattery: boolean;
  food: boolean;
  packingStatus: number;
  managerMessage: string;
  trackingNumber: string;
  useVacuumPackagingBag?: number;
  boxNumbers: number[];
}

interface ManagerConfirmations {
  optionLetterConfirmed: boolean;
  optionPosterConfirmed: boolean;
  optionAircapConfirmed: boolean;
  optionBagConfirmed: boolean;
  optionStickerConfirmed: boolean;
}

export default function RepackModal({
  show,
  onHide,
  orderNumber,
  refreshData,
}: RepackModalProps) {
  const [items, setItems] = useState<Item[]>([
    {
      id: 1,
      trackingNumber: [""],
      weightKg: 0,
      heightCm: 0,
      widthCm: 0,
      lengthCm: 0,
      suspectBattery: false,
      food: false,
    },
  ]);
  const [isMenuOpen, setIsMenuOpen] = useState<{ [key: string]: boolean }>({});
  const [trackingToBoxNumber, setTrackingToBoxNumber] = useState<{
    [key: string]: number;
  }>({});
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [enteredTrackings, setEnteredTrackings] = useState<string[]>([]);
  const [orderDetails, setOrderDetails] = useState<OrderDetails>({
    products: [],
    optionBox: 0,
    optionLetter: false,
    optionPoster: false,
    optionAircap: false,
    optionBag: false,
    optionSticker: false,
    adrCountry: 0,
    weightKg: 0,
    heightCm: 0,
    widthCm: 0,
    lengthCm: 0,
    useVacuumPackagingBag: 0,
    suspectBattery: false,
    food: false,
    packingStatus: 0,
    managerMessage: "특이사항 없습니다.",
    trackingNumber: "",
    boxNumbers: [],
  });
  const [packagedQuantity, setPackagedQuantity] = useState(0);
  const [unpackagedQuantity, setUnpackagedQuantity] = useState(0);
  useEffect(() => {
    const packaged = items.reduce(
      (acc, item) =>
        acc + item.trackingNumber.filter((tn) => tn.trim() !== "").length,
      0
    );
    setPackagedQuantity(packaged);
    setUnpackagedQuantity(orderDetails.products.length - packaged);
  }, [items, orderDetails.products]);
  const [managerConfirmations, setManagerConfirmations] =
    useState<ManagerConfirmations>({
      optionLetterConfirmed: false,
      optionPosterConfirmed: false,
      optionAircapConfirmed: false,
      optionBagConfirmed: false,
      optionStickerConfirmed: false,
    });
  const [isLoading, setIsLoading] = useState(false);
  // 송장 번호 입력에 따라 물품 리스트를 보기 편하게 렌더링 하기 위함
  const [prePackagedProductList, setPrePackagedProductList] = useState<
    Product[]
  >([]);
  const [afterPackagedProductList, setAfterPackagedProductList] = useState<
    Product[]
  >([]);

  const inputRefs = useRef<{ [key: string]: HTMLInputElement | null }>({});

  const isOptionKey = (key: any): key is keyof OrderDetails => {
    return key in orderDetails;
  };

  const isConfirmationKey = (key: any): key is keyof ManagerConfirmations => {
    return key in managerConfirmations;
  };

  const handleShowConfirmModal = () => {
    const allItemsHaveImages = items.every((item) => item.imagePreview);

    if (!allItemsHaveImages) {
      Swal.fire({
        title: "모든 아이템에 사진이 필요합니다.",
        text: "각 아이템에 대한 사진을 추가해주세요.",
        icon: "warning",
        confirmButtonText: "확인",
      });
      return;
    }

    Swal.fire({
      title: "최고 관리자에게 견적서를 제출하시겠습니까?",
      text: "",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "승인",
      cancelButtonText: "취소",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        const allOptionsConfirmed = options.every(
          ({ option, confirmedOption }) => {
            if (isOptionKey(option) && isConfirmationKey(confirmedOption)) {
              if (option === "optionBag") {
                return (
                  orderDetails.products.some((product) => product.bag) ===
                  managerConfirmations[confirmedOption]
                );
              } else {
                return (
                  orderDetails[option] === managerConfirmations[confirmedOption]
                );
              }
            }
            return false;
          }
        );

        if (!allOptionsConfirmed) {
          Swal.fire(
            "모든 요청 정보에 대한 관리자 확인이 필요합니다. 모든 옵션이 확인되지 않았습니다.",
            "",
            "warning"
          );
          return;
        }

        if (![1, 2, 3].includes(orderDetails.packingStatus)) {
          Swal.fire({
            title: "포장 상태를 선택해주세요.",
            text: "전체포장완료, 공간부족, 30kg 초과 중 하나를 선택해주세요.",
            icon: "warning",
            confirmButtonText: "확인",
          });
          return;
        }

        if (items[0].trackingNumber.length === 0) {
          Swal.fire("송장번호를 추가하여 입력해주세요.", "", "warning");
          return;
        }

        const isValidTrackingNumberSet = checkIsValidTrackingNumberSet();
        if (!isValidTrackingNumberSet) {
          Swal.fire(
            "입력된 송장번호가 매칭되지 않습니다.",
            "다시 확인 부탁드립니다.",
            "warning"
          );
          return;
        }

        // 프린터로 라벨 출력
        printLabel();
        setShowConfirmModal(true);
      }
    });
  };

  const checkIsValidTrackingNumberSet = () => {
    let count = 0;
    orderDetails.products.map((product, index) => {
      const isTrackingEntered = items[0].trackingNumber.includes(
        product.tracking
      );
      if (isTrackingEntered) {
        count += 1;
      }
    });
    if (count !== items[0].trackingNumber.length) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const response = await reqShip({ id: orderNumber });
        setOrderDetails(response.data);

        const productList = response.data.products.slice();
        const orderedProductList = productList.sort(
          (a: Product, b: Product) => {
            const aLastDigit = parseInt(a.tracking.slice(-1), 10);
            const bLastDigit = parseInt(b.tracking.slice(-1), 10);
            return aLastDigit - bLastDigit;
          }
        );
        setPrePackagedProductList(orderedProductList);

        const newIsMenuOpen: Record<string, boolean> = {};
        response.data.products.forEach((item: any, index: number) => {
          newIsMenuOpen[`packingDetail${index}`] = true;
        });
        setIsMenuOpen(newIsMenuOpen);
      } catch (error) {
        console.error("Error fetching order details: ", error);
      }
    };

    if (orderNumber) {
      fetchOrderDetails();
    }
  }, [orderNumber]);

  const updateBoxNumber = () => {
    const boxNumbers = items.map((_, index) => index + 1);
    setOrderDetails((prev) => ({
      ...prev,
      boxNumbers,
    }));
  };

  const handleItemCheckboxChange = (
    id: number,
    field: string,
    checked: boolean
  ) => {
    setItems(
      items.map((item) =>
        item.id === id ? { ...item, [field]: checked } : item
      )
    );
  };

  const handleFileChange = (event: any, itemId: number) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      if (reader.readyState === 2) {
        setItems(
          items.map((item) => {
            if (item.id === itemId) {
              return { ...item, file, imagePreview: reader.result as string };
            }
            return item;
          })
        );
      }
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleSubmitEstimate = async () => {
    try {
      // 전체 포장 완료가 선택된 경우
      if (orderDetails.packingStatus === 1) {
        const allItemsAssignedBox = items.every((item) =>
          item.trackingNumber.every(
            (tracking) => tracking && trackingToBoxNumber[tracking] > 0
          )
        );

        if (
          orderDetails.products.length !== packagedQuantity ||
          unpackagedQuantity !== 0 ||
          !allItemsAssignedBox
        ) {
          Swal.fire({
            title: "전체 포장이 완료되지 않았습니다.",
            text: "포장 수량이 요청 수량과 같고, 미포함 수량이 0이어야 하며 모든 박스 번호가 할당되어 있어야 합니다.",
            icon: "warning",
            confirmButtonText: "확인",
          });
          return;
        }
      }

      // 나머지 필드 값 검증
      for (const item of items) {
        if (
          item.weightKg <= 0 ||
          item.heightCm <= 0 ||
          item.widthCm <= 0 ||
          item.lengthCm <= 0
        ) {
          Swal.fire({
            title: "잘못된 입력",
            text: "무게, 가로, 세로, 높이는 0보다 커야 합니다.",
            icon: "warning",
            confirmButtonText: "확인",
          });
          return;
        }
      }

      const boxUpdatePromises = items.flatMap((item) =>
        item.trackingNumber.map(async (trackingNumber) => {
          if (trackingNumber) {
            const boxNumber = trackingToBoxNumber[trackingNumber];
            const boxedValue = boxNumber || 0;
            await reqShipProductBox({
              tracking: trackingNumber,
              boxed: boxedValue,
            });
          }
        })
      );
      await Promise.all(boxUpdatePromises);
      await reqShipUpdateOrder({ id: orderNumber, status: 3 });

      const imageUploadPromises = items.map(async (item, index) => {
        const trackingNumbersStr = item.trackingNumber.join(",");
        const detailId = `${orderNumber}-${index + 1}`;

        let imageUrl = item.imageUrl;
        if (item.file) {
          if (item.file.size > 5 * 1024 * 1024) {
            throw new Error("Image size should not exceed 5MB.");
          }

          setIsLoading(true);
          const uploadResponse = await reqUploadShipOrderImage(
            detailId,
            item.file
          );
          imageUrl = uploadResponse.data.imageUrl;
          setIsLoading(false);
        }

        const detailData: any = {
          id: detailId,
          orderId: orderNumber,
          trackingNumbers: trackingNumbersStr,
          suspectBattery: item.suspectBattery,
          food: item.food,
          packingStatus: Number(orderDetails.packingStatus),
          imageUrl,
        };

        if (item.weightKg > 0) detailData.totalWeightKg = Number(item.weightKg);
        if (item.heightCm > 0) detailData.totalHeightCm = Number(item.heightCm);
        if (item.widthCm > 0) detailData.totalWidthCm = Number(item.widthCm);
        if (item.lengthCm > 0) detailData.totalLengthCm = Number(item.lengthCm);

        console.log("Sending detail data with imageUrl:", detailData);
        await reqShipProductDetailUpdate(detailData);
        return await reqPutExpress({
          id: detailId,
          isDeleted: false,
        });
      });

      await Promise.all(imageUploadPromises);

      await reqShipOrderUpdate({
        id: orderNumber,
        packagingAt: new Date(),
        managerMessage: orderDetails.managerMessage,
        packingStatus: Number(orderDetails.packingStatus),
        useVacuumPackagingBag: orderDetails.useVacuumPackagingBag,
      });

      printOrderDetails();
      setShowConfirmModal(false);
      onHide();
      refreshData();
      window.location.reload();
    } catch (error) {
      console.error("Error during order update:", error);
      alert("사진 크기가 5MB이상입니다.");
    }
  };

  const handleAddItem = () => {
    const newItemId =
      items.length > 0 ? Math.max(...items.map((item) => item.id)) + 1 : 1;
    const newItems = [
      ...items,
      {
        id: newItemId,
        trackingNumber: [""],
        weightKg: 0,
        heightCm: 0,
        widthCm: 0,
        lengthCm: 0,
        suspectBattery: false,
        food: false,
      },
    ];
    setItems(newItems);

    const updatedIsMenuOpen = {
      ...isMenuOpen,
      [`packingDetail${items.length}`]: true,
    };
    setIsMenuOpen(updatedIsMenuOpen);
  };

  const handleReset = () => {
    setItems([
      {
        id: 1,
        trackingNumber: [""],
        weightKg: 0,
        heightCm: 0,
        widthCm: 0,
        lengthCm: 0,
        suspectBattery: false,
        food: false,
      },
    ]);
    window.location.reload();
    onHide();
  };

  const handleManagerConfirmationChange = (
    field: keyof ManagerConfirmations,
    value: boolean
  ) => {
    setManagerConfirmations((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleItemChange = (id: number, field: string, value: string) => {
    const numericValue = parseFloat(value) || 0;

    setItems(
      items.map((item) => {
        if (item.id === id) {
          const updatedItem = { ...item, [field]: numericValue };

          if (["widthCm", "heightCm", "lengthCm"].includes(field)) {
            const { widthCm, heightCm, lengthCm } = updatedItem;
            updatedItem.volumetricWeightFedexUps =
              (widthCm * heightCm * lengthCm) / 5000;
            updatedItem.volumetricWeightEmsKPacket =
              (widthCm * heightCm * lengthCm) / 6000;
          }

          return updatedItem;
        }
        return item;
      })
    );
  };

  // 물품별 송장 번호 입력 이벤트
  const handleTrackingNumberChanges = (
    itemId: number,
    index: number,
    value: string
  ) => {
    const trimmedValue = value.trim();

    setItems((prevItems) =>
      prevItems.map((item) =>
        item.id === itemId
          ? {
              ...item,
              trackingNumber: item.trackingNumber.map((tn, tnIndex) =>
                tnIndex === index ? trimmedValue : tn
              ),
            }
          : item
      )
    );

    // 박스 번호 업데이트
    // setTrackingToBoxNumber((prevMapping) => ({
    //   ...prevMapping,
    //   [trimmedValue]: itemId,
    // }));
  };

  // 엔터 입력시 발동
  const handleAddTrackingNumber = (itemId: number) => {
    setItems((prevItems) => {
      const updatedItems = prevItems.map((item) => {
        if (item.id === itemId) {
          if (item.trackingNumber.length === 0) {
            // 추적 번호가 없는 경우 빈 문자열을 추가하여 시작
            return {
              ...item,
              trackingNumber: [...item.trackingNumber, ""],
            };
          }

          const lastTrackingNumber =
            item.trackingNumber[item.trackingNumber.length - 1].trim();

          if (lastTrackingNumber === "") {
            Swal.fire({
              title: "트래킹 번호를 입력해주세요.",
              text: "유효한 트래킹 번호를 입력해주세요.",
              icon: "warning",
              confirmButtonText: "확인",
            });
            return item;
          }

          // 중복된 송장 번호인지 체크
          const allTrackingNumbers = prevItems.flatMap(
            (itm) => itm.trackingNumber
          );
          const duplicateCount = allTrackingNumbers.filter(
            (tn) => tn === lastTrackingNumber
          ).length;

          if (duplicateCount > 1) {
            Swal.fire(
              "중복된 송장번호입니다.",
              "다시 입력해주세요.",
              "warning"
            );
            return item;
          }

          // 존재하는 트래킹 번호인지 체크
          const trackingExists = orderDetails.products.some(
            (product) => product.tracking === lastTrackingNumber
          );

          if (!trackingExists) {
            Swal.fire({
              title: "존재하지 않는 트래킹 번호입니다.",
              text: "유효한 트래킹 번호를 입력해주세요.",
              icon: "warning",
              confirmButtonText: "확인",
            });
            return item;
          }

          // trackingToBoxNumber 업데이트
          setTrackingToBoxNumber((prevMapping) => ({
            ...prevMapping,
            [lastTrackingNumber]: itemId, // Box number 설정
          }));

          // enteredTrackings 업데이트
          setEnteredTrackings((prev) => [...prev, lastTrackingNumber]);

          return {
            ...item,
            trackingNumber: [...item.trackingNumber, ""],
          };
        }
        return item;
      });

      // 포커스 이동 로직
      setTimeout(() => {
        const item = updatedItems.find((item) => item.id === itemId);
        if (item) {
          const newInputKey = `${itemId}-${item.trackingNumber.length - 1}`;
          if (inputRefs.current[newInputKey]) {
            inputRefs.current[newInputKey]?.focus();
          }
        }
      }, 0);

      return updatedItems;
    });
  };

  const handleRemoveTrackingNumber = (itemId: number, index: number) => {
    const trackingNumberToRemove = items.find((item) => item.id === itemId)
      ?.trackingNumber[index];

    setItems(
      items.map((item) =>
        item.id === itemId
          ? {
              ...item,
              trackingNumber: item.trackingNumber.filter(
                (_, tnIndex) => tnIndex !== index
              ),
            }
          : item
      )
    );

    setTrackingToBoxNumber((prevTrackingToBoxNumber) => {
      const updatedTrackingToBoxNumber = { ...prevTrackingToBoxNumber };
      if (trackingNumberToRemove) {
        delete updatedTrackingToBoxNumber[trackingNumberToRemove];
      }
      return updatedTrackingToBoxNumber;
    });

    setEnteredTrackings((prevEnteredTrackings) =>
      prevEnteredTrackings.filter((tn) => tn !== trackingNumberToRemove)
    );
  };

  const handlePackingStatusChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setOrderDetails({ ...orderDetails, packingStatus: Number(e.target.value) });
  };

  const renderTrackingNumberFields = (item: Item) => (
    <>
      {item.trackingNumber.map((trackingNumber, index) => (
        <div key={`${item.id}-${index}`} className="input-group mb-3">
          <input
            type="text"
            className="form-control"
            placeholder="Tracking number"
            onKeyPress={handleKeyPress}
            value={(trackingNumber || "").toUpperCase()}
            onChange={(e) =>
              handleTrackingNumberChanges(item.id, index, e.target.value)
            }
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleAddTrackingNumber(item.id);
              }
            }}
            ref={(el) => (inputRefs.current[`${item.id}-${index}`] = el)}
          />

          <button
            type="button"
            className="btn btn-danger"
            onClick={() => handleRemoveTrackingNumber(item.id, index)}
          >
            Delete
          </button>
        </div>
      ))}
      <button
        type="button"
        className="btn btn-primary"
        onClick={() => handleAddTrackingNumber(item.id)}
      >
        Add Tracking Number
      </button>
    </>
  );

  const handleManagerMessageChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setOrderDetails((prev) => ({ ...prev, managerMessage: e.target.value }));
  };

  // 물품 전체 삭제 버튼 클릭
  const handleRemoveItem = (id: number) => {
    const removeItem = items.find((item: Item) => item.id === id) as Item;
    if (removeItem !== undefined) {
      removeItem.trackingNumber.map((trackingNumber: string, index: number) => {
        송장_번호_매칭_제거(removeItem.id, index);
      });
    }

    setItems(items.filter((item) => item.id !== id));
  };

  const options = [
    {
      label: "편지봉투 박스포장",
      option: "optionLetter",
      confirmedOption: "optionLetterConfirmed",
    },
    {
      label: "포스터튜브 최소화",
      option: "optionPoster",
      confirmedOption: "optionPosterConfirmed",
    },
    {
      label: "에어캡 추가",
      option: "optionAircap",
      confirmedOption: "optionAircapConfirmed",
    },
    {
      label: "스티커 부착",
      option: "optionSticker",
      confirmedOption: "optionStickerConfirmed",
    },
    {
      label: "진공포장백 사용",
      option: "optionBag",
      confirmedOption: "optionBagConfirmed",
    },
  ];

  const handleKeyPress = (event: any) => {
    if (event.key === " ") {
      event.preventDefault();
    }
  };

  const [vacuumBagCount, setVacuumBagCount] = useState(0);

  const handleVacuumBagCountChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setVacuumBagCount(Number(e.target.value));
  };

  const renderAccordionItem = (item: any, index: any) => (
    <div className="accordion-item" key={item.id}>
      <h2 className="accordion-header">
        {/* 물품 Header */}
        <button
          className="accordion-button collapsed"
          type="button"
          onClick={() => {
            setIsMenuOpen({
              ...isMenuOpen,
              [`packingDetail${index}`]: !isMenuOpen[`packingDetail${index}`],
            });
          }}
          style={{
            fontWeight: "bold",
          }}
        >
          {`${orderNumber}-${index + 1}`}

          <button
            type="button"
            className="btn btn-danger"
            onClick={() => handleRemoveItem(item.id)}
            style={{ marginLeft: "1rem" }}
          >
            Delete
          </button>
        </button>
      </h2>

      {/* 물품별 송장번호 입력란 */}
      <Collapse in={isMenuOpen[`packingDetail${index}`]}>
        <div className="accordion-body">
          <div className="input-group mb-3">
            {renderTrackingNumberFields(item)}
          </div>
          <div>
            <Form.Check
              label="배터리 포함 의심상품"
              type="checkbox"
              checked={item.suspectBattery}
              onChange={(e) =>
                handleItemCheckboxChange(
                  item.id,
                  "suspectBattery",
                  e.target.checked
                )
              }
            />
            <Form.Check
              label="음식"
              type="checkbox"
              checked={item.food}
              onChange={(e) =>
                handleItemCheckboxChange(item.id, "food", e.target.checked)
              }
            />
          </div>
          <div className="input-group mb-3">
            <span className="input-group-text">KG</span>
            <input
              type="number"
              step="0.1"
              className="form-control"
              placeholder="Weight in Kg"
              value={item.weightKg}
              onChange={(e) =>
                handleItemChange(item.id, "weightKg", e.target.value)
              }
              onFocus={(e) => {
                if (e.target.value === "0") e.target.value = "";
              }}
            />
            <span className="input-group-text">가로</span>
            <input
              type="number"
              step="0.1"
              className="form-control"
              placeholder="Width in Cm"
              value={item.widthCm}
              onChange={(e) =>
                handleItemChange(item.id, "widthCm", e.target.value)
              }
              onFocus={(e) => {
                if (e.target.value === "0") e.target.value = "";
              }}
            />
            <span className="input-group-text">세로</span>
            <input
              type="number"
              step="0.1"
              className="form-control"
              placeholder="Length in Cm"
              value={item.lengthCm}
              onChange={(e) =>
                handleItemChange(item.id, "lengthCm", e.target.value)
              }
              onFocus={(e) => {
                if (e.target.value === "0") e.target.value = "";
              }}
            />
            <span className="input-group-text">높이</span>
            <input
              type="number"
              step="0.1"
              className="form-control"
              placeholder="Height in Cm"
              value={item.heightCm}
              onChange={(e) =>
                handleItemChange(item.id, "heightCm", e.target.value)
              }
              onFocus={(e) => {
                if (e.target.value === "0") e.target.value = "";
              }}
            />
          </div>
          <div className="input-group mb-3">
            <span className="input-group-text">
              FedEx/UPS Volumetric Weight
            </span>
            <input
              type="text"
              className="form-control"
              readOnly
              value={`${item.volumetricWeightFedexUps?.toFixed(2) || 0} kg`}
            />
            <span className="input-group-text">
              EMS/K-Packet Volumetric Weight
            </span>
            <input
              type="text"
              className="form-control"
              readOnly
              value={`${item.volumetricWeightEmsKPacket?.toFixed(2) || 0} kg`}
            />
          </div>
          <div className="input-group">
            <input
              type="file"
              className="form-control"
              id={`inputGroupFileRepack${item.id}`}
              onChange={(event) => handleFileChange(event, item.id)}
            />
            <button className="btn btn-outline-secondary" type="button">
              사진촬영
            </button>
          </div>
          {item.imagePreview && (
            <ImageTmpSection>
              <img src={item.imagePreview} alt="Preview" />
            </ImageTmpSection>
          )}
        </div>
      </Collapse>
    </div>
  );

  const tableRef = useRef<HTMLDivElement>(null);

  const printOrderDetails = () => {
    let htmlContent = `
      <html>
        <head>
          <style>
            @page {
              size: 8cm 6cm;
              margin: 0;
            }
            body {
              margin: 0;
              font-family: 'Arial', sans-serif;
            }
            .page {
              width: 8cm;
              height: 6cm;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              page-break-after: always;
              border: 1px solid black;
              padding: 0;
              box-sizing: border-box;
            }
            svg {
              width: 6cm;
              height: 1.5cm;
            }
            p, th, td {
              margin: 0;
              padding: 2px 0;
              text-align: center;
            }
            table {
              width: 100%;
              border-collapse: collapse;
              border: 1px solid black;
            }
            th, td {
              border: 1px solid black;
              padding: 4px;
            }
            th {
              background-color: #f2f2f2;
            }
            .checkmark {
              display: inline-block;
              width: 10px;
              height: 10px;
              border: solid black;
              border-width: 0 3px 3px 0;
              transform: rotate(45deg);
            }
            .large-font {
              font-size: 1.5em;
            }
          </style>
        </head>
        <body>
    `;

    // tracking 번호의 마지막 문자가 낮은 순서대로 정렬
    const sortedProducts = [...orderDetails.products].sort((a, b) => {
      const aLastChar = a.tracking.slice(-1);
      const bLastChar = b.tracking.slice(-1);
      return aLastChar.localeCompare(bLastChar);
    });

    sortedProducts.forEach((product, index) => {
      const lastEightChars = product.tracking.slice(-8);
      const firstFourChars = lastEightChars.slice(0, 4);
      const secondFourChars = lastEightChars.slice(4, 8);
      const urgentText = product.isUrgent
        ? "<div class='checkmark'></div>"
        : "";
      const vacuumText = product.bag ? "<div class='checkmark'></div>" : "";
      const svgBarcode = `<svg id="barcode-${index}"></svg>`;

      htmlContent += `
        <div class="page">
          ${svgBarcode}
          <table>
            <tbody>
              <tr>
                <td colspan="4">${product.tracking}</td>
              </tr>
              <tr class="large-font">
                <td colspan="2">${firstFourChars}</td>
                <td colspan="2">${secondFourChars}</td>
              </tr>
              <tr>
                <td>긴급</td>
                <td>${urgentText}</td>
                <td>진공</td>
                <td>${vacuumText}</td>
              </tr>
            </tbody>
          </table>
        </div>
      `;
    });

    htmlContent += `
        </body>
      </html>
    `;

    const printWindow = window.open("", "_blank", "width=800,height=600");
    if (printWindow) {
      printWindow.document.open();
      printWindow.document.write(htmlContent);
      printWindow.document.close();

      sortedProducts.forEach((product, index) => {
        const barcodeElement = printWindow.document.getElementById(
          `barcode-${index}`
        );
        JsBarcode(barcodeElement, product.tracking, {
          format: "CODE128",
          displayValue: false,
          width: 2,
          height: 30,
          margin: 0,
        });
      });

      printWindow.focus();
      setTimeout(() => {
        printWindow.print();
        printWindow.close();
      }, 500);
    } else {
      console.error("Failed to open the print window.");
    }
  };

  // 견적서 제출 버튼 클릭시 라벨 출력
  const printLabel = () => {
    let htmlContent = `
      <html>
        <head>
          <style>
            @page {
              size: 8cm 6cm;
              margin: 0;
            }
            body {
              margin: 0;
              font-family: 'Arial', sans-serif;
            }
            .page {
              width: 8cm;
              height: 6cm;
              display: flex;
              flex-direction: column;
              justify-content: space-around;
              align-items: center;
              page-break-after: always;
              border: 1px solid black;
              padding: 0;
              box-sizing: border-box;
              font-size: 24px;
            }
            svg {
              width: 6cm;
              height: 1.5cm;
            }
            p, th, td {
              margin: 0;
              padding: 2px 0;
              text-align: center;
            }
            table {
              width: 100%;
              border-collapse: collapse;
              border: 1px solid black;
            }
            th, td {
              border: 1px solid black;
              padding: 4px;
            }
            th {
              background-color: #f2f2f2;
            }
            .checkmark {
              display: inline-block;
              width: 10px;
              height: 10px;
              border: solid black;
              border-width: 0 3px 3px 0;
              transform: rotate(45deg);
            }
          </style>
        </head>
        <body>
    `;

    items.map((item: Item, index: number) => {
      const includeSticker = managerConfirmations.optionStickerConfirmed;
      const includeVacuumBag = managerConfirmations.optionBagConfirmed;

      htmlContent += `
        <div class="page">
          <p>${orderNumber}-${index + 1}</p>
          <p>${item.weightKg} kg</p>
          ${includeSticker ? "<p>스티커 부착</p>" : ""}
          ${includeVacuumBag ? "<p>진공포장백 사용</p>" : ""}
        </div>
        <div class="page">
          <p>${orderNumber}-${index + 1}</p>
          <p>${item.weightKg} kg</p>
          ${includeSticker ? "<p>스티커 부착</p>" : ""}
          ${includeVacuumBag ? "<p>진공포장백 사용</p>" : ""}
        </div>
      `;
    });

    htmlContent += `
        </body>
      </html>
    `;

    const printWindow = window.open("", "_blank", "width=800,height=600");
    if (printWindow) {
      printWindow.document.open();
      printWindow.document.write(htmlContent);
      printWindow.document.close();

      printWindow.focus();
      setTimeout(() => {
        printWindow.print();
        printWindow.close();
      }, 500);
    } else {
      console.error("Failed to open the print window.");
    }
  };

  // const handleManagerMessageChange = (
  //   e: React.ChangeEvent<HTMLTextAreaElement>
  // ) => {
  //   setOrderDetails((prev) => ({ ...prev, managerMessage: e.target.value }));
  // };

  // // 진공포장백 수량 변경 핸들러 추가
  // const handleVacuumBagCountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setOrderDetails((prev) => ({
  //     ...prev,
  //     useVacuumPackagingBag: Number(e.target.value),
  //   }));
  // };

  const 송장_번호_매칭_추가 = () => {
    for (const item of items) {
      const trackingNumberList: string[] = item.trackingNumber;
      for (const trackingNumber of trackingNumberList) {
        const packedProduct = prePackagedProductList.find(
          (product: Product) => product.tracking === trackingNumber
        );

        if (packedProduct) {
          const modifiedFilteredProductList = prePackagedProductList.filter(
            (product: Product) => product.tracking !== packedProduct.tracking
          );
          setPrePackagedProductList(modifiedFilteredProductList);
          afterPackagedProductList.push(packedProduct);
        }
      }
    }
  };

  const 송장_번호_매칭_제거 = (
    unpackagedItemId: number,
    unpackagedIndex: number
  ) => {
    const unpackagedTrackingNumber = items.find(
      (item: Item) => item.id === unpackagedItemId
    )?.trackingNumber[unpackagedIndex];

    if (!unpackagedTrackingNumber) {
      console.error("Unpackaged tracking number not found.");
      return;
    }

    const unpackagedProduct = afterPackagedProductList.find(
      (product: Product) => product.tracking === unpackagedTrackingNumber
    );

    if (!unpackagedProduct) {
      console.error("Unpackaged product not found.");
      return;
    }

    // 값이 null일 경우 기본값 할당
    unpackagedProduct.checkEmail = unpackagedProduct.checkEmail ?? "";
    unpackagedProduct.checkPayment = unpackagedProduct.checkPayment ?? 0;
    unpackagedProduct.checkPrice = unpackagedProduct.checkPrice ?? 0;

    const packaedProductList = [...prePackagedProductList, unpackagedProduct]
      .sort((a: Product, b: Product) => {
        const aLastDigit = parseInt(a.tracking.slice(-1), 10);
        const bLastDigit = parseInt(b.tracking.slice(-1), 10);
        return aLastDigit - bLastDigit;
      })
      .filter((product: Product) => product !== undefined);

    setPrePackagedProductList(packaedProductList);

    setAfterPackagedProductList([
      ...afterPackagedProductList.filter(
        (product: Product) => product.tracking !== unpackagedTrackingNumber
      ),
    ]);
  };

  const 물품_리스트_자동정렬_컴포넌트 = () => {
    송장_번호_매칭_추가();
    const uniqueAfterPackagedProductList = afterPackagedProductList.filter(
      (product: Product, index: number) => {
        return afterPackagedProductList.indexOf(product) === index;
      }
    );
    return (
      <>
        {[...prePackagedProductList, ...uniqueAfterPackagedProductList].map(
          (product, index) => {
            const boxNumber = trackingToBoxNumber[product.tracking];
            const boxLabel = boxNumber
              ? `Box ${boxNumber}`
              : "Box not assigned";
            const isMatched = items.some((item) =>
              item.trackingNumber.includes(product.tracking)
            );

            return (
              <tr
                key={index}
                className={isMatched ? "table-success matched" : ""}
                style={{
                  backgroundColor: isMatched ? "lightgreen" : "inherit",
                }}
              >
                <td>{index + 1}</td>
                <td>{product.tracking}</td>
                <td>
                  <Form.Check
                    type="checkbox"
                    checked={product.isUrgent}
                    disabled
                  />
                </td>
                <td>
                  <Form.Check type="checkbox" checked={product.bag} disabled />
                </td>
                <td>{boxLabel}</td>
              </tr>
            );
          }
        )}
      </>
    );
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        onHide();
        window.location.reload();
      }}
      size="xl"
      fullscreen={true}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title style={{ fontWeight: "bold" }}>
          포장 관리 - {orderNumber}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className="main-container-boxing">
            {/* 상품 포장 현황 */}
            <div className="left-panel-boxing">
              <div className="accordion" id="accordionRepack">
                <div className="accordion-item">
                  <div
                    id="collapseRepack"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingRepack"
                    data-bs-parent="#accordionRepack"
                  >
                    <div className="accordion-body">
                      <Accordion defaultActiveKey="item-1">
                        {items.map((item, index) =>
                          renderAccordionItem(item, index)
                        )}
                      </Accordion>
                      <div className="col-12 text-center">
                        {orderDetails.optionBox !== 0 && (
                          <div className="col-12 text-center">
                            <button
                              className="btn btn-outline-secondary mt-1 col-12"
                              onClick={(event) => {
                                event.preventDefault();
                                handleAddItem();
                              }}
                              style={{ fontWeight: "bold" }}
                            >
                              Add+
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* 상품 목록 */}
            <div className="middle-panel-boxing">
              <div className="table-responsive-boxing">
                <table className="table text-center table-hover">
                  <thead>
                    <tr>
                      <th scope="col">No</th>
                      <th scope="col">Tracking No.</th>
                      <th scope="col">Urgent</th>
                      <th scope="col">Vacuum</th>
                      <th scope="col">Box</th>
                    </tr>
                  </thead>
                  <tbody>
                    <물품_리스트_자동정렬_컴포넌트 />
                  </tbody>
                </table>
              </div>
            </div>

            {/* 관리자 확인 */}
            <div className="right-panel-boxing">
              <CenteredFlexContainer>
                <span>
                  <strong>고객요청</strong>
                </span>
                <span>
                  <strong>관리자 확인</strong>
                </span>
              </CenteredFlexContainer>
              {options.map(({ label, option, confirmedOption }) => {
                let isChecked;
                if (option === "optionBag") {
                  isChecked = orderDetails.products.some(
                    (product) => product.bag
                  );
                } else {
                  isChecked = orderDetails[
                    option as keyof OrderDetails
                  ] as boolean;
                }
                const isConfirmed =
                  managerConfirmations[
                    confirmedOption as keyof ManagerConfirmations
                  ];

                const isDisabled = !isChecked;

                return (
                  <OptionContainer key={option}>
                    <Form.Check
                      label={label}
                      checked={isChecked}
                      disabled
                      inline
                    />
                    <Form.Check
                      label={`${label} (확인)`}
                      checked={isConfirmed}
                      onChange={(e) =>
                        handleManagerConfirmationChange(
                          confirmedOption as keyof ManagerConfirmations,
                          e.target.checked
                        )
                      }
                      inline
                      disabled={isDisabled}
                    />
                  </OptionContainer>
                );
              })}
              <Form.Group>
                <Form.Label>진공포장백 사용 수량</Form.Label>
                <Form.Control
                  type="number"
                  value={orderDetails.useVacuumPackagingBag?.toString() || 0}
                  onChange={(e) =>
                    setOrderDetails({
                      ...orderDetails,
                      useVacuumPackagingBag: Number(e.target.value),
                    })
                  }
                />
              </Form.Group>
              <div
                style={{ borderBottom: "1px solid #dee2e6", margin: "10px 0" }}
              ></div>
              <div
                style={{
                  marginBottom: "20px",
                  fontSize: "1.6rem",
                }}
              >
                <div>
                  <strong>
                    배송 국가: {CODE_COUNTRY_ITEMS[orderDetails.adrCountry]}
                  </strong>
                </div>
                <div
                  style={{
                    borderBottom: "1px solid #dee2e6",
                    margin: "10px 0",
                  }}
                ></div>
                <div>
                  <strong>요청 수량: {orderDetails.products.length}</strong>
                </div>
                <div
                  style={{
                    borderBottom: "1px solid #dee2e6",
                    margin: "10px 0",
                  }}
                ></div>
                <div>
                  <strong>포장 수량: {packagedQuantity}</strong>
                </div>
                <div
                  style={{
                    borderBottom: "1px solid #dee2e6",
                    margin: "10px 0",
                  }}
                ></div>
                <div>
                  <strong>미포함 수량: {unpackagedQuantity}</strong>
                </div>
                <div
                  style={{
                    borderBottom: "1px solid #dee2e6",
                    margin: "10px 0",
                  }}
                ></div>
              </div>
              <Form.Check
                type="radio"
                label="전체포장완료"
                name="packingStatus"
                id="fullPacked"
                value={1}
                checked={orderDetails.packingStatus === 1}
                onChange={handlePackingStatusChange}
              />
              <div
                style={{ borderBottom: "1px solid #dee2e6", margin: "10px 0" }}
              ></div>
              <Form.Check
                type="radio"
                label="공간부족"
                name="packingStatus"
                id="spaceLack"
                value={2}
                checked={orderDetails.packingStatus === 2}
                onChange={handlePackingStatusChange}
              />
              <div
                style={{ borderBottom: "1px solid #dee2e6", margin: "10px 0" }}
              ></div>
              <Form.Check
                type="radio"
                label="30kg 초과"
                name="packingStatus"
                id="over30kg"
                value={3}
                checked={orderDetails.packingStatus === 3}
                onChange={handlePackingStatusChange}
              />
              <div
                style={{ borderBottom: "1px solid #dee2e6", margin: "10px 0" }}
              ></div>
              <div className="card mt-3">
                <div className="card-header">
                  <h4>관리자에게 보내는 메시지</h4>
                </div>
                <div className="card-body">
                  <textarea
                    className="w-100"
                    value={orderDetails.managerMessage}
                    onChange={handleManagerMessageChange}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </Form>

        <Modal
          show={showConfirmModal}
          onHide={() => setShowConfirmModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>견적서 전송 확인</Modal.Title>
          </Modal.Header>
          <Modal.Body>최고관리자에게 견적서를 전송하시겠습니까?</Modal.Body>

          {isLoading ? (
            <LoadingSection>
              <img src="/resources/img/loading.gif" alt="로딩중" />
            </LoadingSection>
          ) : null}

          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowConfirmModal(false)}
            >
              Back
            </Button>
            <Button variant="primary" onClick={handleSubmitEstimate}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      </Modal.Body>

      <Modal.Footer style={{ gap: "1rem" }}>
        <Button variant="secondary" onClick={printOrderDetails}>
          라벨 출력
        </Button>
        <Button variant="secondary" onClick={handleReset}>
          초기화
        </Button>
        <Button variant="primary" onClick={handleShowConfirmModal}>
          견적서 제출
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const LoadingSection = styled.div`
  position: absolute;
  z-index: 1000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  & > img {
    width: 100%;
  }
`;

const ImageTmpSection = styled.div`
  margin-top: 1rem;
  & > img {
    max-width: 30rem;
    max-height: 30rem;
    object-fit: scale-down;
  }
`;

const CenteredFlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
`;

const OptionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  padding: 10px;
  border-radius: 8px;
  background-color: #f8f9fa;
`;
